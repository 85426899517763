import React from 'react';
import Logo from '../../images/svg/URL-Shortener.fileextension.svg';
import Link from '../acf/link';

function BlueLogo(){
    return(
        <Link to="/" aria-label="Rebrandly Logo">
            <Logo/>
        </Link>
    )
}

export default BlueLogo;