export const TRIAL_PLAN = 'free trial'
export const FREE_PLAN = 'free'
export const LITE_PLAN = 'lite'

export const STARTER_PLAN = 'essentials'
export const STARTER_PLAN_500 = 'essentials_500'

export const PRO_PLAN = 'professional'
export const PRO_PLAN_3500 = 'professional_3500'

export const ENTERPRISE_PLAN = 'enterprise'
export const ENTERPRISE_PLAN_20K = 'enterprise_20K'
export const ENTERPRISE_PLAN_50K = 'enterprise_50K'
export const ENTERPRISE_PLAN_100K = 'enterprise_100K'

export const PREMIUM_PLAN = 'pro+'
