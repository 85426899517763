const config = {
  google: {
    client: {
      scriptUri: "https://accounts.google.com/gsi/client",
      id: "586723695602-grf8l9uqbv6muojatqr5e5irledrf1ld.apps.googleusercontent.com",
    },
  },
  zendesk: {
    key: "6e6ea4b8-a514-441c-9788-114e8a441c11",
  },
  ld: {
    clientId: process.env.LD_CLIENT_ID,
    sharedKey: "anonymous-user-id",
  },
  dashboardBaseUrl: process.env.DASH_BASE_URL,
  oauthBaseUrl: process.env.OAUTH_BASE_URL,
  clientId: process.env.CLIENT_ID,
  clientIdNoVerification: process.env.CLIENT_ID_NO_VERIFICATION,
  apiBaseUrl: process.env.API_BASE_URL || "https://api.test.rebrandly.com/v1",
  domainSuggestionsBaseUrl: process.env.DOMAIN_SUGGESTIONS_BASE_URL || "https://domain-suggestions.rebrandly.com/api/v1",
  buildEnv: process.env.BUILD_ENV,
};

export default config;
