import Item from "../footer/item";
import React from "react";
import PropTypes from "prop-types";

/**
 * FooterMenuItem component that renders a list of menu items.
 *
 * This component takes an array of menu nodes and renders an Item component
 * for each node. Each Item component receives `uri`, `label`, and `target`
 * as props.
 *
 * @param {Object} props - The props object.
 * @param {Array} props.nodes - The array of menu nodes to be rendered.
 * 
 * @returns {React.ReactElement} The rendered FooterMenuItem component.
 */
export const FooterMenuItem = ({nodes = []}) => {
  return <div className="item">
    {nodes.map((menu, index) => (
      <Item
        uri={menu.uri}
        label={menu.label}
        target={menu.target}
        key={index}
      />
    ))}
  </div>
}

FooterMenuItem.propTypes = {
  nodes: PropTypes.array
};