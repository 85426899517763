import React from 'react';
import get from 'lodash/get'
import { Label, Selector, Alert, Icon, A } from '@rebrandly/styleguide';
import { usageOptions, helpOptions } from '../utils/constants'
import { func, object } from 'prop-types';
import { pluralize } from 'humanize-plus';
import capitalize from 'lodash/capitalize';

function PlanSuggester({
                         formData,
                         handleChange
                       }) {

  const helpValue = helpOptions.find(s => s.value === get(formData, 'helpType'))
  const usageValue = usageOptions.find(s => s.value === get(formData, 'usage'))
  const volumeValue = usageValue && usageValue.volumeSelector && usageValue.volumeSelector.options.find(s => s.value === get(formData, 'volume'))

  return (
      <>
        <div className="Form__group col-xs-12">
          <Label size="lg" value="How can our team help you? *">
            <Selector
                name="helpType"
                options={helpOptions}
                triggerSize="large"
                placeholder="Select an option"
                value={helpValue}
                onChange={(option) => handleChange('helpType', option.value)}
                closeOnSelect
            />
          </Label>
        </div>
        {!!helpValue && helpValue.showUsage
            && (
                <>
                  <div className="Form__group col-xs-12">
                    <Label size="lg" value="How do you plan to use Rebrandly? *">
                      <Selector
                          name="usage"
                          options={usageOptions}
                          triggerSize="large"
                          placeholder="Select an option"
                          value={usageValue}
                          onChange={(option) => handleChange('usage', option.value)}
                          closeOnSelect
                      />
                    </Label>
                  </div>
                  {!!usageValue && usageValue.volumeSelector
                      && (
                          <div className="Form__group col-xs-12">
                            <Label size="lg" value={`${usageValue.volumeSelector.label} *`}>
                              <Selector
                                  name="volume"
                                  options={usageValue.volumeSelector.options}
                                  triggerSize="large"
                                  placeholder="Select an option"
                                  value={volumeValue}
                                  onChange={(option) => handleChange('volume', option.value)}
                                  closeOnSelect
                              />
                            </Label>
                            {!!volumeValue && volumeValue.plans
                                && (
                                    <Alert
                                        className="m-t-12"
                                        title={(
                                            <span>
                        Our
                                              {volumeValue.plans.map(capitalize).join(' and ')}
                                              {' '}
                                              {pluralize('Plan', volumeValue.plans.length)}
                                              {' '}
                                              suits your needs!
                                              {' '}
                                              <A className="nowrap" href="/pricing" size="lg" target="_blank">Go to the pricing page</A>
                      </span>
                                        )}
                                        image={<Icon size="xsmall" color="var(--color-blue-600)" name="ic-help" />}
                                        bgColor="var(--color-blue-50)"
                                        noBorder
                                    />
                                )}
                          </div>
                      )}
                </>
            )}
      </>
  );
}

PlanSuggester.propTypes = {
  formData: object,
  handleChange: func
};

export default PlanSuggester;
