export const FREE_PLAN = 'free'
export const LITE_PLAN = 'lite'
export const STARTER_PLAN = 'essentials'
export const PRO_PLAN = 'professional'
export const PREMIUM_PLAN = 'pro+'
export const ENTERPRISE_PLAN = 'enterprise'

const plans = {
    [ENTERPRISE_PLAN]: {
        features: [
            /* DOMAINS */
            { id: 'domains.register' },
            { id: 'domains.connect' },
            { id: 'domains.whitelabeled.homepage' },
            { id: 'domains.whitelabeled.fallback' },
            { id: 'domains.customSSL' },
            /* EXTRA */
            { id: 'extra.link_history' },
            { id: 'extra.301_redirect' },
            { id: 'extra.account_management' },
            { id: 'extra.api_access' },
            { id: 'extra.browser_extension' },
            { id: 'extra.consolidated_billing' },
            { id: 'extra.custom_logo_report' },
            { id: 'extra.custom_saas_agreement' },
            { id: 'extra.custom_url_slug' },
            { id: 'extra.customizable_api_rate' },
            { id: 'extra.dedicated_engineer_support' },
            { id: 'extra.dedicated_load_balancers' },
            { id: 'extra.dedicated_onboarding' },
            { id: 'extra.direct_training' },
            { id: 'extra.editable_destination_url' },
            { id: 'extra.enterprise_tool_integration' },
            { id: 'extra.email_support' },
            { id: 'extra.agent_support' },
            { id: 'extra.gdpr_privacy' },
            { id: 'extra.domain_404_clicks_tracking' },
            { id: 'extra.https_ssl' },
            { id: 'extra.knowledge_base' },
            { id: 'extra.link_analytics' },
            { id: 'extra.link_search' },
            { id: 'extra.mac_dekstop_app' },
            { id: 'extra.mobile_apps' },
            { id: 'extra.multiple_access_levels' },
            { id: 'extra.multiple_apps_integrations' },
            { id: 'extra.parameter_forwarding' },
            { id: 'extra.server_click_stream' },
            { id: 'extra.single_sign_on' },
            { id: 'extra.uptime_sla' },
            { id: 'extra.utm_builder' },
            { id: 'extra.video_tutorials' },
            { id: 'extra.windows_desktop_app' },
            { id: 'extra.custom_domain_hostnames' },
            { id: 'extra.linkgallery' },
            /* LINKS */
            { id: 'links.unlimited_clicks' },
            { id: 'links.extended' },
            { id: 'links.opengraph' },
            { id: 'links.apps' },
            { id: 'links.emoji' },
            // { id: 'links.export' },
            { id: 'links.import' },
            { id: 'links.jobs' },
            { id: 'links.notes' },
            { id: 'links.qrcode' },
            { id: 'links.rules' },
            { id: 'links.scripts' },
            { id: 'links.tags' },
            { id: 'links.utm' },
            { id: 'links.expiration' },
            /* REPORTS */
            { id: 'metrics.advanced' },
            { id: 'reports.custom' },
            { id: 'reports.public' },
            /* COLLABORATION */
            { id: 'collaboration.teammates' },
            { id: 'collaboration.workspaces' },
            /* OTHER */
            { id: 'security.mfa' },
            { id: 'extra.add_ons' },
            { id: 'stats.charts', config: { 'clickType': true, 'weekdays': true, 'dayhours': true, 'sources': true, 'socials': true, 'devices': true, 'browsers': true, 'platforms': true, 'languages': true } }
        ],
        limits: {
            domains: {included: '10+'},
            links: {included: '10,000+'},
            teammates: {included: 'custom'},
            workspaces: {included: 'custom'},
            cycle: { 'links-classic': {included: '10,000+'}, links: {included: 'custom'}, clicks: { included: '1,500,000+' }, reports: { included: '5+' }, teammates: { included: 'custom' } }
        }
    }
}

export default plans
