import React, {useEffect, useState} from 'react'
import classnames from 'classnames';
import { Icon, Dropdown, Trigger, Content, Button } from '@rebrandly/styleguide'
import {redirectToSignUp} from '../../utils/signup-redirect'
import {useFlags} from 'gatsby-plugin-launchdarkly'
import * as style from './navigation.module.css';

function MenuList({ menus, closeMenu, onToggleSubmenu, useBtnLinkClass = false, isLogged = false }) {
  const [subMenuSelected, setSubMenuSelected] = useState()
  const [mounted, setMounted] = useState(false)
  const flags = useFlags()

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
    }
  }, [mounted])

  const renderSubMenuButton = (item, index) => {
    return (
      <>
        <div className='display-none display-md-block'>
          <Dropdown className={classnames('MegaMenu__Dropdown', style.mega_menu, style[`mega_menu_${index}`])} mustOpenOnHover>
            <Trigger>
              <a
                tabIndex={index + 1}
                className={classnames('flex middle-xs nav_link', style.nav_link, item.cssClasses, {
                  [style.btn_link]: useBtnLinkClass,
                  'btn_user_logged': isLogged
                })}
              >
                {item.label}
                <Icon className='m-l-4' name='ic-chevron-down' size='xsmall' />
              </a>
            </Trigger>
            <Content className={classnames(style.megamenu_content, style[`megamenu_content_${index}`])}>
              <item.subMenu />
            </Content>
          </Dropdown>
        </div>

        <div className='display-md-none'>
          <button
            tabIndex={index + 1}
            className={style.nav_link}
            onClick={() => {
              setSubMenuSelected(item.id)
              onToggleSubmenu(!!item.id)
            }}
          >
            {item.label}
          </button>
        </div>
      </>
    )
  }

  if (subMenuSelected) {
    const menu = menus.find(i => i.id === subMenuSelected)
    if (!!menu) {
      return (
        <div>
          <div className={style.sub_menu_header}>
            <Button
              theme='link'
              label='Back'
              icon={{ name: 'ic-arrow-back', size: 'sm' }}
              onClick={() => {
                setSubMenuSelected(null)
                onToggleSubmenu(false)
              }}
            />
          </div>
          <menu.subMenu onClick={closeMenu} />
        </div>
      )
    }
  }
  return (
    <nav className={style.nav_menu}>
      <ul className={style.nav_box}>
        {menus.map((item, index) => {
          let isGetStarted = !!item.cssClasses?.find(_class => _class === 'get-started-button')
          if (isGetStarted && flags.freeSignUp) {
            item.uri = redirectToSignUp()
          }
          return (
            <li key={item.label}>
              {(!!item?.uri || !mounted)
                ? <a
                  tabIndex={index + 1}
                  href={item.uri}
                  target={item.target}
                  className={classnames('nav_link', style.nav_link, item.cssClasses, {
                    [style.btn_link]: useBtnLinkClass,
                    'btn_user_logged': isLogged
                  })}
                  onClick={closeMenu}
                >
                  {item.label}
                </a>
                : renderSubMenuButton(item, index)
              }
            </li>
          )
        })}
      </ul>
    </nav>
  );
}

export default MenuList;
