import React, {useContext, useState} from 'react'
// import { graphql, useStaticQuery } from 'gatsby';
import NavigationList from './navigationList'
import Products from './sub-menu/Products'
import Resources from './sub-menu/Resources'

import ButtonsMenu from './buttonsMenu'
import {AppContext} from '../layout/default'

import * as style from './style.module.css';
import './style.css';

const menuNodes = [
  {
    id: 'products',
    label: 'Products',
    subMenu: Products
  },
  {
    id: 'domains',
    label: 'Domains',
    uri: '/newdomain'
  },
  {
    id: 'pricing',
    label: 'Pricing',
    uri: '/pricing'
  },
  {
    id: 'resources',
    label: 'Resources',
    subMenu: Resources
  }
]

function HomeMenu({ closeMenu }) {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  // const { headerMenu } = useStaticQuery(graphql`
  //   query headerMenu {
  //     headerMenu: allWpMenuItem(filter: { locations: { eq: HEADER_MENU } }) {
  //       nodes {
  //         uri
  //         label
  //         target
  //         cssClasses
  //       }
  //     }
  //   }
  // `);

  const handleMenuItemClick = () => {
    closeMenu();
  };
  const { user, cookieConsent } = useContext(AppContext)
  const showUserAsLogged = !!user.id && cookieConsent

  return (
    <div className='HeaderMenu'>
      <div className={style.links_wrapp}>
        <NavigationList menus={menuNodes} onToggleSubmenu={setIsSubMenuOpen} closeMenu={handleMenuItemClick} />
      </div>
      {!isSubMenuOpen &&
        <div className={style.buttons_wrap}>
          <ButtonsMenu isLogged={showUserAsLogged}/>
        </div>
      }
    </div>
  );
}

export default HomeMenu;
