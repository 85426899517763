import {prefixOptions as prefixes, GMT_OFFICE_TIME_END, GMT_OFFICE_TIME_START} from './constants'
import get from 'lodash/get'
import moment from 'moment'

export function buildPhoneWithValue (value, selectedPrefix) {
  if (!value) {
    return ''
  }

  const prefixOption = prefixes.find(o => o.value === selectedPrefix)
  const prefix = get(prefixOption, 'prefix', '')

  return `${prefix}.${value}`
}

export function buildPhoneWithPrefix (countryCode, phone) {
  if (!phone) {
    return ''
  }

  let [prefix, phoneNumber] = phone.split('.')
  const prefixOption = prefixes.find(o => o.value === countryCode)
  prefix = get(prefixOption, 'prefix', '')
  return `${prefix}.${phoneNumber}`
}

export const parsePhoneNumber = data => {
  const phone = get(data, 'phone', '')
  const phoneCountryCode = get(data, 'phoneCountryCode')

  if (!phone) {
    return { value: '' }
  }

  const parts = data.phone.split('.')
  const prefix = prefixes.find(o => o.label === parts[0])
  return {
    prefix: phoneCountryCode || get(prefix, 'value', ''),
    value: parts[1]
  }
}

export const getLocalOfficeHours = () => (
  {
    startTime: GMT_OFFICE_TIME_START.local().format('hA'),
    endTime: GMT_OFFICE_TIME_END.local().format('hA'),
    timeZone: moment().local().format('Z')
  }
)

const isWeekend = (date) => date.day() === 6 || date.day() === 0

export const isOfficeHour = () => {
  const time = moment.utc()

  return time.isBetween(GMT_OFFICE_TIME_START, GMT_OFFICE_TIME_END) && !isWeekend(time)
}
