import React, { PureComponent } from "react";
import { object, bool, func, string, node } from "prop-types";
import {
  Selector,
  Label,
  Input,
  Button,
  Text,
  Checkbox,
  A,
  Icon,
  Textarea,
} from "@rebrandly/styleguide";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import isURL from "validator/lib/isURL";
import isEmail from "validator/lib/isEmail";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";

import {
  buildPhoneWithPrefix,
  buildPhoneWithValue,
  parsePhoneNumber,
} from "../utils/helpers";
import setUpHSFormsAndRevenueHero from "../utils/integration";
import {
  requiredFields,
  companySizeOptions,
  demoOptions,
  prefixOptions as prefixes,
  usageOptions,
  helpOptions,
} from "../utils/constants";
import config from "../utils/config";
import PlanSuggester from "./PlanSuggester";

import "./ContactUsDataForm.css";

class ContactUsDataForm extends PureComponent {
  constructor(props) {
    super(props);

    const detectedCountry = get(
      window,
      "rebrandly.routing.viewer.country",
      ""
    ).toLowerCase();
    const prefix = prefixes.find((p) => p.value === detectedCountry);

    this.state = {
      data: { ...this.props.data, accountType: null },
      prefixOptions: orderBy(prefixes, "label"),
      selectedPrefix:
        parsePhoneNumber(this.props.data).prefix || get(prefix, "value", null),
      errors: {},
      loadingCaptcha: true,
      dataConfirmed: props.showDemoField,
      privacyConfirmed: props.showDemoField,
    };

    this.config = config(this.props.env);
    this.recaptchaRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({
        ...this.state,
        selectedPrefix:
          parsePhoneNumber(this.props.data).prefix || this.state.selectedPrefix,
        data: {
          ...this.props.data,
          accountType: get(this.props.data, "accountType", null),
        },
      });
    }

    if (!isEqual(prevProps.errors, this.props.errors)) {
      this.setState({
        ...this.state,
        errors: {
          ...this.props.errors,
        },
      });
    }
  }

  componentDidMount() {
    if (this.props.showHubspotForm) {
      setUpHSFormsAndRevenueHero(document);
    }
  }

  hasErrors = () => {
    let valid = true;
    Object.values(this.state.errors).forEach((e) => {
      valid = valid && !e;
    });

    return !valid;
  };

  canSubmit = () => {
    let required = [...requiredFields];

    const usageOption = usageOptions.find(
      (s) => s.value === get(this.state.data, "usage")
    );
    const helpOption = helpOptions.find(
      (s) => s.value === get(this.state.data, "helpType")
    );

    if (!this.props.showDemoField) {
      required = required.filter((f) => f.field !== "demo");
    }
    const hidePlanSuggesterAndComment =
      this.props.hideDirectQuestions ||
      this.props.showDemoField ||
      (helpOption && !helpOption.showUsage);

    if (hidePlanSuggesterAndComment) {
      required = required.filter((f) => f.field !== "usage");
      required = required.filter((f) => f.field !== "volume");
    } else if (usageOption && !usageOption.volumeSelector) {
      required = required.filter((f) => f.field !== "volume");
    }
    const notValidFields = required.filter(
      (f) => !get(this.state.data, f.field)
    );
    return (
      this.state.dataConfirmed &&
      this.state.privacyConfirmed &&
      isEmpty(notValidFields) &&
      !this.hasErrors(this.state.errors)
    );
  };

  handleChange = (name, value) => {
    if (!name) {
      return;
    }

    let newState = {
      [name]: value,
    };
    let newErrors = { errors: { ...this.state.errors, [name]: false } };
    let selectedPrefix = {
      selectedPrefix:
        name === "phonePrefix" ? value : this.state.selectedPrefix,
    };

    if (name === "website") {
      const isValidUrl = isURL(value) || value === "";
      newErrors = {
        errors: {
          ...this.state.errors,
          website: !isValidUrl && {
            theme: "warning",
            message: "Invalid value",
          },
        },
      };
    }

    if (name === "companyEmail") {
      const isValidEmail = isEmail(value) || value === "";
      newErrors = {
        errors: {
          ...this.state.errors,
          companyEmail: !isValidEmail && {
            theme: "warning",
            message: "Invalid value",
          },
        },
      };
    }

    if (name === "phone") {
      const valueOnlyDigits = value.replace(/\D/g, "");
      newState.phone = buildPhoneWithValue(
        valueOnlyDigits,
        this.state.selectedPrefix
      );
      newErrors = {
        errors: {
          ...this.state.errors,
          phone: valueOnlyDigits.length > 3 &&
            !this.state.selectedPrefix && {
              theme: "warning",
              message: "Please, select a country code",
            },
        },
      };
    }

    if (name === "phonePrefix") {
      newState = {
        phone: buildPhoneWithPrefix(value, this.state.data.phone),
      };
      newErrors = {
        errors: {
          ...this.state.errors,
          phone: false,
        },
      };
    }

    if (name === "usage") {
      const usageOption = usageOptions.find((s) => s.value === value);
      if (!usageOption.volumeSelector) {
        newState = {
          ...newState,
          volume: null,
        };
      }
    }
    if (name === "helpType") {
      const helpOption = helpOptions.find((s) => s.value === value);
      if (!helpOption.showUsage) {
        newState = {
          ...newState,
          usage: null,
          volume: null,
        };
      }
    }

    this.setState({
      ...selectedPrefix,
      ...newErrors,
      data: {
        ...this.state.data,
        ...newState,
      },
    });
  };

  onCaptchaChange = (captcha) => {
    if (!captcha) return;

    this.props.onResetErrors && this.props.onResetErrors();
    this.props.onSubmit(
      { ...this.state.data, loggedIn: this.props.isLogged },
      captcha
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.recaptchaRef.current) return;

    this.recaptchaRef.current.reset();
    this.recaptchaRef.current.execute();
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  onDataConfirm = () => {
    this.setState({
      dataConfirmed: !this.state.dataConfirmed,
    });
  };

  onPrivacyConfirm = () => {
    this.setState({
      privacyConfirmed: !this.state.privacyConfirmed,
    });
  };

  render() {
    let secondaryButtonProps = this.props.secondaryButton
      ? { ...this.props.secondaryButton }
      : null;
    if (get(this.props, "secondaryButton.check")) {
      secondaryButtonProps = {
        ...this.props.secondaryButton,
        disabled: !this.canSubmit(),
      };
    }

    const hasContent =
      React.isValidElement(this.props.content) && this.props.content;
    const captchaTheme =
      this.props.theme === "default" ? "light" : this.props.theme;
    const reCaptcha = (
      <ReCAPTCHA
        ref={this.recaptchaRef}
        className={classNames("g-recaptcha", {
          "g-recaptcha-error": this.state.errors.captcha,
        })}
        sitekey={this.config.captcha.contactUsKey}
        size={"invisible"}
        badge={"bottomleft"}
        onChange={this.onCaptchaChange}
        asyncScriptOnLoad={() => this.setState({ loadingCaptcha: false })}
        theme={captchaTheme}
      />
    );

    const prefixOptions = this.state.prefixOptions.map((option) => {
      return {
        ...option,
        label: (
          <span>
            <i className={"m-r-8 flag flag--tiny flag-" + option.value} />{" "}
            {option.label}
          </span>
        ),
        tooltip: { overlay: option.name },
        searchString: option.label + " " + option.name,
      };
    });

    if (
      this.state.errors.captcha &&
      this.state.errors.captcha.code === "InvalidChallenge"
    ) {
      this.recaptchaRef.current.reset();
    }

    if (this.props.phoneOnly) {
      return (
        <div className="Container--narrow form">
          <Text size="large" className="Text-SemiHero text-center m-t-12">
            Call us now <span className="font-semibold">+1 (415) 702-2047</span>
          </Text>
          <Text size="small" className="Text--SubDetail text-center">
            Monday to Friday - 5:00AM to 11:00PM (GMT)
          </Text>

          <div className="OnBoarding__footer--buttons m-t-24 row end-xs m-l-24 m-r-24">
            {secondaryButtonProps && (
              <div className="col-xs-12 col-sm-12 middle-xs flex center">
                <Button
                  className="Text-SubDetail"
                  {...secondaryButtonProps}
                  size="medium"
                />
              </div>
            )}
          </div>
        </div>
      );
    }
    return (
      <div
        className={`${hasContent ? "Container--wide" : "Container--narrow"} 
        row ContactUsDataForm`}
      >
        <div
          className={classNames("ContactUsData__form col-xs-12 m-sm-48", {
            "col-md-7": hasContent,
          })}
        >
          {this.props.title && (
            <Text
              size={hasContent ? "x-large" : "large"}
              className="Text-Title"
            >
              {this.props.title}
            </Text>
          )}

          {this.props.showHubspotForm && <div id="HubspotExternalContainer" />}
          {!this.props.showHubspotForm && (
            <>
              {!!this.props.onOtherOptions &&
                !this.props.hideDirectQuestions && (
                  <div className="m-t-4">
                    <Text size="x-small" className="Text--SubDetail">
                      Fill the form below or{" "}
                      <A size="sm" onClick={this.props.onOtherOptions}>
                        view other options
                      </A>{" "}
                      to contact us
                    </Text>
                  </div>
                )}

              <form
                className="m-t-24"
                onChange={(e) =>
                  this.handleChange(e.target.name, e.target.value)
                }
                onKeyDown={this.handleKeyDown}
                onSubmit={this.handleSubmit}
                noValidate
              >
                <div className="ContactUsData__form--fields row">
                  <div className="Form__group col-xs-12 col-sm-6">
                    <Label size="lg" value="First name *">
                      <Input
                        name="firstName"
                        value={get(this.state.data, "firstName", "")}
                        placeholder="Your name"
                        {...this.state.errors.firstName}
                      />
                    </Label>
                  </div>
                  <div className="Form__group col-xs-12 col-sm-6">
                    <Label size="lg" value="Last name *">
                      <Input
                        name="lastName"
                        value={get(this.state.data, "lastName", "")}
                        placeholder="Your surname"
                        {...this.state.errors.lastName}
                      />
                    </Label>
                  </div>
                  <div className="Form__group col-xs-12 col-md-12 col-sm-6">
                    <Label size="lg" value="Business email *">
                      <Input
                        name="companyEmail"
                        value={get(this.state.data, "companyEmail", "")}
                        placeholder="Business email address"
                        {...this.state.errors.companyEmail}
                      />
                    </Label>
                  </div>

                  <div className="Form__group col-xs-12 col-sm-6 col-md-12">
                    <Label value="Contact number" size="lg">
                      <div className="row m-t-4">
                        <div
                          className={classNames("PrefixSelector col-xs-5", {
                            "col-sm-4": !hasContent,
                            "col-sm-4 col-md-3": hasContent,
                          })}
                        >
                          <Selector
                            className="Text--Detail"
                            triggerSize="large"
                            options={prefixOptions}
                            value={prefixOptions.find(
                              (p) => p.value === this.state.selectedPrefix
                            )}
                            onChange={(option) =>
                              this.handleChange("phonePrefix", option.value)
                            }
                            placeholder="Code"
                            closeOnSelect
                            showSearch
                          />
                        </div>
                        <div className="PhoneInput col-xs">
                          <Input
                            size="lg"
                            name="phone"
                            value={parsePhoneNumber(this.state.data).value}
                            placeholder="Phone number"
                            type="tel"
                            {...this.state.errors.phone}
                          />
                        </div>
                      </div>
                    </Label>
                  </div>
                  <div
                    className={classNames("Form__group col-xs-12", {
                      "col-sm-6": true,
                    })}
                  >
                    <Label size="lg" value="Company website">
                      <Input
                        name="website"
                        value={get(this.state.data, "website", "")}
                        placeholder="www.company.com"
                        {...this.state.errors.website}
                      />
                    </Label>
                  </div>
                  <div
                    className={classNames("Form__group col-xs-12", {
                      "col-sm-6": true,
                    })}
                  >
                    <Label size="lg" value="Company size">
                      <Selector
                        name="companySize"
                        options={companySizeOptions}
                        triggerSize="large"
                        placeholder="Select an option"
                        value={companySizeOptions.find(
                          (s) => s.value === get(this.state.data, "companySize")
                        )}
                        onChange={(option) =>
                          this.handleChange("companySize", option.value)
                        }
                        closeOnSelect
                      />
                    </Label>
                  </div>
                  {!this.props.showDemoField &&
                    !this.props.hideDirectQuestions && (
                      <>
                        <PlanSuggester
                          formData={this.state.data}
                          handleChange={this.handleChange}
                        />

                        <div className="col-xs-12">
                          <Label size="lg" value="Your message">
                            <Textarea
                              className="ContactUs__textarea"
                              name="notes"
                              value={get(this.state.data, "notes", "")}
                              placeholder="Anything you want to add?"
                            />
                          </Label>
                        </div>
                      </>
                    )}
                  {this.props.showDemoField && (
                    <div className={classNames("col-xs-12", "col-sm-12")}>
                      <Label
                        size="lg"
                        value="Would you like to see Rebrandly in action? *"
                      >
                        <Selector
                          name="demo"
                          options={demoOptions}
                          triggerSize="large"
                          placeholder="Select an option"
                          value={demoOptions.find(
                            (s) => s.value === get(this.state.data, "demo")
                          )}
                          onChange={(option) =>
                            this.handleChange("demo", option.value)
                          }
                          closeOnSelect
                        />
                      </Label>
                    </div>
                  )}

                  <div className="m-t-4 m-b-24 col-xs-12">
                    <Text size="x-small" className="Text--SubDetail">
                      * Mandatory fields
                    </Text>
                  </div>

                  {!this.props.showDemoField && (
                    <div className="col-xs-12">
                      <Label value="I hereby declare that the information provided above is true and correct">
                        <Checkbox
                          checked={this.state.dataConfirmed}
                          onClick={this.onDataConfirm}
                        />
                      </Label>
                      <Label
                        className="m-t-8"
                        value={
                          <span>
                            I have read and agree to the{" "}
                            <A href="/privacy-policy" size="md" target="_blank">
                              privacy policy
                            </A>
                          </span>
                        }
                      >
                        <Checkbox
                          checked={this.state.privacyConfirmed}
                          onClick={this.onPrivacyConfirm}
                        />
                      </Label>
                    </div>
                  )}
                </div>

                {reCaptcha}
                {this.state.errors.captcha && (
                  <div className="m-t-24 flex middle-xs">
                    <Icon
                      className="m-r-8"
                      name="ic-warning"
                      color="var(--color-red-600)"
                      size="tiny"
                    />
                    <Text className="Text-HighlightRed" size="x-small">
                      {this.state.errors.captcha.message}
                    </Text>
                  </div>
                )}

                <div className="OnBoarding__footer--buttons m-t-36">
                  {secondaryButtonProps && (
                    <Button {...secondaryButtonProps} block />
                  )}
                  <Button
                    {...this.props.submitButton}
                    disabled={!this.canSubmit()}
                    block
                  />
                </div>
              </form>
            </>
          )}
        </div>
        {hasContent && (
          <>
            {!this.props.hideSideContent && (
              <div className="SideContent col-xs-12 col-md-5">
                {this.props.content}
                {!this.props.hideCustomerLogos && (
                  <div>
                    <Text size="medium" className="Text--SubDetail">
                      Trusted by the world’s smartest brands:
                      <div className="CustomersLogos m-t-24">
                        <img
                          src={
                            "https://dashboard-cdn.rebrandly.com/dashboard-assets/images/logos/logo-customer-converse.svg"
                          }
                          alt="customer-converse"
                        />
                        <img
                          src={
                            "https://dashboard-cdn.rebrandly.com/dashboard-assets/images/logos/logo-customer-iberia.svg"
                          }
                          alt="customer-iberia"
                        />
                        <img
                          src={
                            "https://dashboard-cdn.rebrandly.com/dashboard-assets/images/logos/logo-customer-paramount.svg"
                          }
                          alt="customer-iparamount"
                        />
                        <img
                          src={
                            "https://dashboard-cdn.rebrandly.com/dashboard-assets/images/logos/logo-customer-nba.svg"
                          }
                          alt="customer-nba"
                        />
                        <img
                          src={
                            "https://dashboard-cdn.rebrandly.com/dashboard-assets/images/logos/logo-customer-monday.svg"
                          }
                          alt="customer-monday"
                        />
                        <img
                          src={
                            "https://dashboard-cdn.rebrandly.com/dashboard-assets/images/logos/logo-customer-samsonite.svg"
                          }
                          alt="customer-samsonite"
                        />
                        <img
                          src={
                            "https://dashboard-cdn.rebrandly.com/dashboard-assets/images/logos/logo-customer-toyota.svg"
                          }
                          alt="customer-toyota"
                        />
                        <img
                          src={
                            "https://dashboard-cdn.rebrandly.com/dashboard-assets/images/logos/logo-customer-three.svg"
                          }
                          alt="customer-three"
                        />
                        <img
                          src={
                            "https://dashboard-cdn.rebrandly.com/dashboard-assets/images/logos/logo-customer-versace.svg"
                          }
                          alt="customer-versace"
                        />
                        <img
                          src={
                            "https://dashboard-cdn.rebrandly.com/dashboard-assets/images/logos/logo-customer-ferrero.svg"
                          }
                          alt="customer-ferrero"
                        />
                        <img
                          src={
                            "https://dashboard-cdn.rebrandly.com/dashboard-assets/images/logos/logo-customer-epic-games.svg"
                          }
                          alt="customer-epic-games"
                        />
                        <img
                          src={
                            "https://dashboard-cdn.rebrandly.com/dashboard-assets/images/logos/logo-customer-abb.svg"
                          }
                          alt="customer-abb"
                        />
                      </div>
                    </Text>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

ContactUsDataForm.propTypes = {
  env: string,
  title: string,
  data: object,
  errors: object,
  submitButton: object,
  secondaryButton: object,
  onSubmit: func,
  phoneOnly: bool,
  content: node,
  onCancel: func,
  directUrl: bool,
  isLogged: bool,
  hideCustomerLogos: bool,
  showDemoField: bool,
  hideDirectQuestions: bool,
  onResetErrors: func,
  onOtherOptions: func,
  theme: string,
  showHubspotForm: bool,
};

ContactUsDataForm.defaultProps = {
  submitLabel: "Save",
  secondaryLabel: "Cancel",
  showHubspotForm: false,
};

export default ContactUsDataForm;
