import React from "react";
import * as style from "./style.module.css";
import Logo from "../../../images/svg/rebrandly-logo-white.svg";
import Link from "../../acf/link";

function DarkHeader() {
  return (
    <>
      <Link className={style.logo_wrapp} to="/">
        <Logo />
        <p className="logo_text">
          {" "}
          <span>|</span>Enterprise
        </p>
      </Link>
    </>
  );
}

export default DarkHeader;
