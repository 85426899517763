import parse from 'url-parse'
import config from "../config.js"

let redirectToLogin
let redirectToSignUp = () => {}
let redirectToGoogleLogin
if (typeof window !== 'undefined') {
  var stateObj = {
    path: '/',
    search: window.location.search
  }
  var OAUTH__LOGIN_URL = config.oauthBaseUrl + '/connect/authorize'
  var APP_BASE_URL = config.dashboardBaseUrl
  var CLIENT_ID = config.clientId // Official Rebrandly Dashboard Client ID

  var encodeState = (obj = stateObj) => {
    return btoa(JSON.stringify(obj))
  }

  var oauthLoginObject = {
    client_id: CLIENT_ID,
    redirect_uri: APP_BASE_URL,
    state: encodeState(),
    response_type: 'code',
    scope: 'rbapi offline_access rbapikeys rbinvites rbdowngrade'
  }

  var oauthSignUpObject = {
    ...oauthLoginObject,
    acr_values: 'register cmd:direct'
  }

  var oauthLogInObjectNoVerification = {
    ...oauthLoginObject,
    client_id: config.clientIdNoVerification,
    response_type: 'token',
    scope: 'accounting carts'
  }

  var oauthSignUpObjectNoVerification = {
    ...oauthSignUpObject,
    client_id: config.clientIdNoVerification,
    response_type: 'token',
    scope: 'accounting carts'
  }

  var buildUrlRedirectToOauth = (params = oauthLoginObject) => {
    const url = parse(OAUTH__LOGIN_URL)
    url.set('query', params)
    return url.href
  }

  redirectToLogin = (config = {}) => {
    const state = encodeState({
      path: config.path,
      search: config.search
    })

    const params = {
      ...oauthLoginObject,
      ...config.skipVerification && oauthLogInObjectNoVerification,
      state
    }
    window.location = buildUrlRedirectToOauth(params)
  }

  redirectToSignUp = (config = {}) => {
    var s = config.search || window.location.search
    let state = {
      path: config.path || '/setup',
      search: s ? s + '&p=firstlogin' : '?p=firstlogin'
    }
    if (config.links) {
      state.links = config.links
    }

    state = encodeState(state)

    var flow = 'user'

    let acrValues = oauthSignUpObject.acr_values + ' flow:' + flow

    if (config.plan) {
      acrValues = acrValues + ' plan:' + config.plan
    }
    if (config.provider) {
      acrValues = acrValues + ' cmd:provider-' + config.provider
    }

    const params = {
      ...oauthSignUpObject,
      ...config.skipVerification && oauthSignUpObjectNoVerification,
      acr_values: acrValues,
      state
    }

    return buildUrlRedirectToOauth(params)
  }

  redirectToGoogleLogin = (googleToken, config = {}) => {
    let state = {
      path: '/setup',
      search: '?p=firstlogin'
    }

    state = encodeState(state)

    let acrValues = `cmd:provider-google credential:${googleToken}`
    if (config.plan) {
      acrValues = acrValues + ' plan:' + config.plan
    }

    const params = {
      ...oauthSignUpObject,
      acr_values: acrValues,
      state
    }

    window.location = buildUrlRedirectToOauth(params)
  }
} else {
  redirectToLogin = null
}

export {
  redirectToLogin,
  redirectToSignUp,
  redirectToGoogleLogin
}
