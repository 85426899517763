import React from 'react'
import { Text, Icon } from '@rebrandly/styleguide'

import {Link} from 'gatsby'

import * as style from './style.module.css'

const menu = [
  {
    to: 'https://blog.rebrandly.com',
    target: '_blank',
    label: 'Blog',
    icon: 'ic-blog'
  },
  {
    to: '/marketplace',
    label: 'Marketplace',
    icon: 'ic-marketplace'
  },
  {
    to: 'https://developers.rebrandly.com/',
    target: '_blank',
    label: 'Developers Hub',
    icon: 'ic-recipe'
  },
  {
    to: 'https://support.rebrandly.com',
    target: '_blank',
    label: 'Knowledge Base',
    icon: 'ic-bulb'
  },
  {
    to: 'https://trust.rebrandly.com',
    target: '_blank',
    label: 'Trust Center',
    icon: 'ic-shield'
  },
  {
    to: '/free-trial',
    label: 'Free Trial',
    icon: 'ic-gift-box'
  },
  {
    to: '/newdomain',
    label: 'Domain Name Registration',
    icon: 'ic-domain-connect'
  }
]

const Resources = ({ onClick }) => {
  return (
    <div>
      <Text className='Text-Hero m-b-24 display-md-none'>Resources</Text>
      <div className={style.resources_menu_left}>
        <Text className='Text-Hero m-b-24 display-none display-md-block'>Resources</Text>
        <ul>
          {menu
            .map(item => (
              <li>
                <Link href={item.to} target={item.target} onClick={onClick}>
                  <div className={style.menu_link}>
                    <Icon name={item.icon} color='var(--color-grey-800)' size='xsmall' />
                    <span className={style.menu_link_label}>{item.label}</span>
                  </div>
                </Link>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  )
}

export default Resources
