import countries from 'rb-country-data'
import moment from 'moment'
import { PREMIUM_PLAN, PRO_PLAN, STARTER_PLAN } from '../../../config/plans'

export const GMT_OFFICE_TIME_START = moment.utc().set({ 'hour': 5, 'minute': 0, 'second': 0 })
export const GMT_OFFICE_TIME_END = moment.utc().set({ 'hour': 23, 'minute': 0, 'second': 0 });
export const PERSONAL_FIELDS = 'personal'
export const BUSINESS_FIELDS = 'business'
export const requiredFields = [
  {
    field: 'firstName'
  },
  {
    field: 'lastName'
  },
  {
    field: 'companyEmail'
  },
  {
    field: 'demo'
  },
  {
    field: 'usage'
  },
  {
    field: 'volume'
  }
]

export const companySizeOptions = [
  {
    label: 'Single employee',
    value: 'personal'
  },
  {
    label: '10 Employees or less',
    value: '10'
  },
  {
    label: '11 to 250 Employees',
    value: '250'
  },
  {
    label: '251 to 1000 Employees',
    value: '1000'
  },
  {
    label: '1001 Employees or more',
    value: '1001+'
  },
  {
    label: 'Agency',
    value: 'agency'
  },
  {
    label: 'System integrator',
    value: 'systemIntegrator'
  }
]

export const roleOptions = [
  { label: 'Digital Marketing', value: 'digital_marketing' },
  { label: 'Marketing analyst', value: 'marketing_analyst' },
  { label: 'Promotions manager', value: 'promotions_manager' },
  { label: 'Marketing manager', value: 'marketing_manager' },
  { label: 'Product development', value: 'product_development' },
  { label: 'Engineering / Development', value: 'engineering_development' },
  { label: 'Agile coach / Scrum Master', value: 'agile_coach/scrum_master' },
  { label: 'UX Researcher', value: 'ux_researcher' },
  { label: 'UI Designer', value: 'ui_designer' },
  { label: 'Freelance', value: 'freelance' },
  { label: 'Operations', value: 'operations' },
  { label: 'Sales / Success', value: 'sales/success' },
  { label: 'Support', value: 'support' },
  { label: 'Consultant / Professional Services', value: 'consultant/professional_services' },
  { label: 'Customer Experience Management', value: 'customer_experience_management' },
  { label: 'IT services', value: 'it_services' },
  { label: 'Not company related', value: 'not_company_related' },
  { label: 'Teacher', value: 'teacher' },
  { label: 'Student', value: 'student' },
  { label: 'Others', value: 'others' }
]

export const industryOptions = [
  {
    label: 'Influencer',
    value: 'influencer',
    type: PERSONAL_FIELDS
  },
  {
    label: 'Creator',
    value: 'creator',
    type: PERSONAL_FIELDS
  },
  {
    label: 'Consultant',
    value: 'consultant',
    type: PERSONAL_FIELDS
  },
  {
    label: 'Teacher',
    value: 'teacher',
    type: PERSONAL_FIELDS
  },
  {
    label: 'Affiliate',
    value: 'affiliate',
    type: PERSONAL_FIELDS
  },
  {
    label: 'Digital Marketer',
    value: 'digitalMarketer',
    type: PERSONAL_FIELDS
  },
  {
    label: 'Developer',
    value: 'developer',
    type: PERSONAL_FIELDS
  },
  {
    label: 'Designer',
    value: 'designer',
    type: PERSONAL_FIELDS
  },
  {
    label: 'Investor',
    value: 'investor',
    type: PERSONAL_FIELDS
  },
  {
    label: 'Professional',
    value: 'professional',
    type: PERSONAL_FIELDS
  },
  {
    label: 'Education/Religion/Non-profit',
    value: 'nonProfit',
    type: BUSINESS_FIELDS
  },
  {
    label: 'Government entity (.gov)',
    value: 'gov',
    type: BUSINESS_FIELDS
  },
  {
    label: 'Automotive',
    value: 'automotive',
    type: BUSINESS_FIELDS
  },
  {
    label: 'CPG',
    value: 'cpg',
    type: BUSINESS_FIELDS
  },
  {
    label: 'Pharma/Health Care',
    value: 'healthCare',
    type: BUSINESS_FIELDS
  },
  {
    label: 'Food & Beverage/QSR',
    value: 'food',
    type: BUSINESS_FIELDS
  },
  {
    label: 'Travel/Hospitality',
    value: 'travel',
    type: BUSINESS_FIELDS
  },
  {
    label: 'Agency',
    value: 'agency',
    type: BUSINESS_FIELDS
  },
  {
    label: 'Financial Services',
    value: 'financial',
    type: BUSINESS_FIELDS
  },
  {
    label: 'Sports, Media & Entertainment',
    value: 'entertainment',
    type: BUSINESS_FIELDS
  },
  {
    label: 'Market Research',
    value: 'marketResearch',
    type: BUSINESS_FIELDS
  },
  {
    label: 'Technology',
    value: 'technology',
    type: BUSINESS_FIELDS
  },
  {
    label: 'Retail/E-Commerce',
    value: 'ecommerce',
    type: BUSINESS_FIELDS
  },
  {
    label: 'Information/Telecom',
    value: 'information',
    type: BUSINESS_FIELDS
  },
  {
    label: 'Customer service and support',
    value: 'support',
    type: BUSINESS_FIELDS
  },
  {
    label: 'Other',
    value: 'other'
  }
]

export const demoOptions = [
  {
    label: 'Yes, I would like a demo',
    value: 'yes'
  },
  {
    label: 'Maybe, tell me more',
    value: 'maybe'
  },
  {
    label: 'No, not right now',
    value: 'no'
  }
]

export const prefixOptions = countries.map(c => ({
  label: `+${c[2]}`,
  value: c[1],
  name: c[0],
  prefix: `+${c[2]}`
}))


export const useCases = [
  {
    label: 'Create and manage my branded links',
    value: 'links'
  },
  {
    label: 'Brand my links with custom domain name',
    value: 'domain'
  },
  {
    label: 'Create my LinkGallery profile',
    value: 'linkGallery'
  },
  {
    label: 'Use Rebrandly API keys',
    value: 'api'
  },
  {
    label: 'Share branded links across departments',
    value: 'share'
  },
  {
    label: 'Incorporate branded links into my existing integration',
    value: 'existingIntegration'
  },
  {
    label: 'Other',
    value: 'other'
  }
]

export const helpOptions = [
  {
    label: 'I have a pricing question',
    value: 'pricing',
    showUsage: true
  },
  {
    label: 'I want to evaluate Rebrandly for my organization',
    value: 'evaluate',
    showUsage: true
  },
  {
    label: 'I have a product question',
    value: 'product'
  },
  {
    label: 'I have a billing question',
    value: 'billing'
  },
  {
    label: 'I have a technical question',
    value: 'tech'
  },
  {
    label: 'Other',
    value: 'other'
  }
]

export const usageOptions = [
  {
    label: 'High volume clicks campaigns',
    value: 'clicks_campaigns',
    volumeSelector: {
      label: 'Clicks volume',
      options: [
        { label: 'Up to 25,000', value: '<25,000', plans: [STARTER_PLAN] },
        { label: 'Up to 150,000', value: '<150,000', plans: [PRO_PLAN] },
        { label: 'Up to 1,500,000', value: '<1,500,000', plans: [PREMIUM_PLAN] },
        { label: 'More than 1,500,000', value: '>1,500,000' }
      ]
    }
  },
  {
    label: 'High volume links campaigns',
    value: 'extended_links_campaigns',
    volumeSelector: {
      label: 'Links volume',
      options: [
        { label: 'Up to 5,000', value: '<5,000', plans: [STARTER_PLAN] },
        { label: 'Up to 15,000', value: '<15,000', plans: [PRO_PLAN] },
        { label: 'Up to 150,000', value: '<150,000', plans: [PREMIUM_PLAN] },
        { label: 'More than 150,000', value: '>150,000' }
      ]
    }
  },
  {
    label: 'Multiple domains branding',
    value: 'multiple_domains',
    volumeSelector: {
      label: 'Domains volume',
      options: [
        { label: 'Up to 5', value: '<5', plans: [STARTER_PLAN, PRO_PLAN] },
        { label: 'Up to 20', value: '<20', plans: [PREMIUM_PLAN] },
        { label: 'More than 20', value: '>20' }
      ]
    }
  },
  {
    label: 'Multiple teammates access',
    value: 'multiple_teammates',
    volumeSelector: {
      label: 'Teammates volume',
      options: [
        { label: 'Up to 10', value: '<10', plans: [PREMIUM_PLAN] },
        { label: 'More than 10', value: '>10' }
      ]
    }
  },
  {
    label: 'Other',
    value: 'other'
  }
]
