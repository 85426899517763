import configGen from './config'

export function submitContactForm (env, payload, captcha) {
  const config = configGen(env)
  const options = {
    url: config.baseUrls.middleware + '/leads',
    method: 'POST',
  }

  return fetch(options.url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'g-recaptcha-response': captcha
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (!response.ok) {
        return response.json()
          .then(r => Promise.reject(r))
      }

      return response.json()
        .then(r => Promise.resolve(r))
    })
}
