import React, { useEffect, useState, useCallback } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FooterMenuItem } from "./footer-menu-item";
import FooterLogo from "../footer-logo";
import { chunkArray } from '../../utils/helpers';
const LINK_FOOTER_TITLE = 'LEARN BRANDED LINK MANAGEMENT WITH REBRANDLY'

/**
 * FooterLinks component that displays a footer menu with links and a logo.
 *
 * This component fetches the footer menu items using a GraphQL query and displays
 * them in columns based on the window size. It also includes a footer logo.
 *
 * @returns {React.ReactElement} The rendered FooterLinks component.
 */
function FooterLinks() {
  const [windowTargetSize, setWindowTargetSize] = useState(null);
  const {
    footerMenu,
  } = useStaticQuery(graphql`
    query FooterMenu {
      footerMenu: allWpMenuItem(filter: { locations: { eq: FOOTER_MENU } }) {
        nodes {
          uri
          label
          target
        }
      }
    }
  `);

  /**
   * Used to save the window size props inside the state ad each resize
   */
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  const handleResize = useCallback((event) => {
    setWindowTargetSize({ width: event.currentTarget.outerWidth, height: event.currentTarget.outerHeight })
  }, [])

  /**
   * If the page if large then compose the array in 5 columns, otherwise in 3 columns
   */
  let footerMenuColumns = 5;
  if (windowTargetSize?.width <= 1024) {
    footerMenuColumns = 3;
  }
  const footerMenus = chunkArray(footerMenu.nodes, footerMenuColumns);

  return (
    <div className={'footer-links-box'}>
      <div className={'container footer-links-container'}>
        <p className="LinksFooter__Title">
          {LINK_FOOTER_TITLE}
        </p>
        <div className="flex-box">
          {
            footerMenus.map(footerMenu => {
              return <FooterMenuItem nodes={footerMenu} />
            })
          }

          <div className="item footer-links-logo">
            <FooterLogo />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterLinks;
