import React from 'react'
import CookieConsent from 'react-cookie-consent'
import {ALLOW_COOKIES} from '../../utils/helpers'

function GTagCookiesScript({ onCookieConsentChange }) {
  const loadGTM = (gtmId) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })

    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`
    document.head.appendChild(script)
  }

  const handleAcceptCookies = () => {
    // Replace 'GTM-XXXX' with your GTM ID
    loadGTM('GTM-NZVWGS3')
    onCookieConsentChange(ALLOW_COOKIES)
    console.log('Cookies accepted and GTM initialized')
  }

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Deny"
        cookieName="cookieconsent_status"
        cookieValue="allow"
        declineCookieValue="deny"
        containerClasses='CookieConsent__container'
        style={{background: '#28384a', zIndex: '99999', fontSize: '18px', padding: '4px 50px'}}
        buttonStyle={{
          color: '#ffffff',
          fontSize: '18px',
          background: '#28384a',
          border: '1px solid #fff',
          borderRadius: '50em',
          padding: '0.4em 1.1em',
          fontWeight: 700,
          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
        declineButtonStyle={{
          color: '#fff',
          fontSize: '18px',
          background: '#28384a',
          fontWeight: 500,
          textAlign: 'center',
          whiteSpace: 'nowrap',
          padding: '0.4em 0.8em',
          margin: '15px 0'
        }}
        flipButtons={true}
        onAccept={handleAcceptCookies}
        enableDeclineButton
        onDecline={() => {
          console.log('Cookies declined')
        }}
        contentStyle={{flex: '1 0 400px', color: '#fff'}}
        expires={150}
      >
        <span className="cc-message">
          We use cookies to ensure you get the best experience on our website and services. Read more about our
          <a aria-label="learn more about cookies" role="button" tabIndex="0" className="cc-link" href="https://www.rebrandly.com/cookies" rel="noopener noreferrer nofollow" style={{color: '#ffffff'}}
             target="_blank">
            Cookie Policy
          </a>
        </span>
      </CookieConsent>

      <script>
        {`
            var options = {
              palette: {
                popup: {
                  background: "#28384a"
                },
                button: {
                  background: "#28384a",
                  text: "#ffffff"
                }
              },
              compliance: {
                'opt-out': '<div class="cc-compliance cc-highlight">{{allow}}{{deny}}</div>'
              },
              elements: {
              },
              type: 'opt-out',
              theme: "classic",
              container: document.getElementById('desktop-nav'),
              position: "top",
              static: true,
              onPopupOpen: function () {
                document.body.classList.add('cc-added')
              },
              onInitialise: function(status) {
                if (status === 'allow') {
                  (function(w, d, s, l, i) {
                    w[l] = w[l] || [];
                    w[l].push({
                        'gtm.start': new Date().getTime(),
                        event: 'gtm.js'
                    });
                    var f = d.getElementsByTagName(s)[0],
                        j = d.createElement(s),
                        dl = l != 'dataLayer' ? '&l=' + l : '';
                    j.async = true;
                    j.src =
                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                    f.parentNode.insertBefore(j, f);
                  })(window, document, 'script', 'dataLayer', 'GTM-NZVWGS3');
                  window.dataLayer = window.dataLayer || []
                  function gtag () { dataLayer.push(arguments) }
                  gtag('js', new Date())

                  gtag('config', 'UA-68215349-1', { 'optimize_id': 'GTM-W5PQBDJ' })

                  function implementManyExperiments (value, name) {
                    if (value === '1') {
                      window.RB_FORM = true
                    } else {
                      window.RB_FORM = false
                    }
                  }
                  gtag('event', 'optimize.callback', {
                    callback: implementManyExperiments
                  })
                }
              },
              onStatusChange: function(status, chosenBefore) {
                document.dispatchEvent(new CustomEvent('cookieConsentChange', {
                  detail: {status}
                }));
                if (status === 'allow') {
                  (function(w, d, s, l, i) {
                    w[l] = w[l] || [];
                    w[l].push({
                        'gtm.start': new Date().getTime(),
                        event: 'gtm.js'
                    });
                    var f = d.getElementsByTagName(s)[0],
                        j = d.createElement(s),
                        dl = l != 'dataLayer' ? '&l=' + l : '';
                    j.async = true;
                    j.src =
                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                    f.parentNode.insertBefore(j, f);
                  })(window, document, 'script', 'dataLayer', 'GTM-NZVWGS3');
                  window.dataLayer = window.dataLayer || []
                  function gtag () { dataLayer.push(arguments) }
                  gtag('js', new Date())

                  gtag('config', 'UA-68215349-1', { 'optimize_id': 'GTM-W5PQBDJ' })

                  function implementManyExperiments (value, name) {
                    if (value === '1') {
                      window.RB_FORM = true
                    } else {
                      window.RB_FORM = false
                    }
                  }
                  gtag('event', 'optimize.callback', {
                    callback: implementManyExperiments
                  })
                }
              },
              onPopupClose: function () {
                document.body.classList.remove('cc-added')
              },
              content: {
                message: "We use cookies to ensure you get the best experience on our website and services.  Read more about our",
                link: "Cookie Policy",
                href: "https://www.rebrandly.com/cookies",
                deny: "Deny",
                allow: "Accept"
              }
            };
            if (document.body && document.body.clientWidth > 1200 && window.location.pathname !== '/linkgallery') {
              let interval = setInterval(function () {
                if (window.cookieconsent) {
                  window.cookieconsent.initialise(options)
                  clearInterval(interval)
                }
              }, 200);
            } else {
              options.container = document.getElementById('mobile-nav');
              options.position = 'bottom';
              options.static = false;
              options.content.message = 'We use cookies to ensure the best experience on our website and services.'
              let interval = setInterval(function () {
                if (window.cookieconsent) {
                  window.cookieconsent.initialise(options)
                  clearInterval(interval)
                }
              }, 200);
            }
          `}
      </script>
    </>
  )
}

export default GTagCookiesScript
