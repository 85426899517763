import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Link from "../acf/link";

import SubMenu from "../subMenu";
import Social from "../socials";

import BlueLogo from "../siteLogo/blueLogo";

import "./style.css";

/**
 * Footer component that renders different types of footers based on the props.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} [props.children] - The children elements to be displayed inside the footer.
 * @param {string} [props.footerType='default'] - The type of footer to be displayed. Can be 'default', 'alternative', or 'none'.
 * @param {boolean} [props.footerPadding] - Whether to apply padding to the footer.
 *
 * @returns {React.ReactElement} The rendered footer component.
 */
function Footer({ children, footerType ='default', footerPadding }) {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className={classnames(
        "footer",
        { default: footerType === "default" },
        { footer_padding: footerPadding }
      )}
    >
      {footerType === "alternative" ? (
        <div className="container simple-container">
          <div className="simple-socials">
            <Social type="main" />
          </div>
          <div className="footer-logo-wrapp">
            <BlueLogo />
          </div>
          <p className="copyright">
            Copyright {currentYear} All rights reserved
          </p>
        </div>
      ) : (
        <>
          {children && <div className="w-full">{children}</div>}
          <div className="sub-footer">
            <div className="container sub-footer-elements">
              <div className="links-sub-footer">
                <SubMenu />
              </div>
              <Link className="footer-copyright" to="/">Rebrandly © Copyright {currentYear}</Link>
            </div>
          </div>
        </>
      )}
    </footer>
  );
}
Footer.propTypes = {
  children: PropTypes.node,
  footerType: PropTypes.oneOf(["default", "alternative", "none"]),
  footerPadding: PropTypes.bool,
};

export default Footer;
