import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Item from "../footer/item";

function SubMenu() {
  const { mainSubMenu } = useStaticQuery(graphql`
    query mainSubMenu {
      mainSubMenu: allWpMenuItem(
        filter: { locations: { eq: FOOTER_SUB_MENU } }
      ) {
        nodes {
          uri
          label
          target
        }
      }
    }
  `);
  return (
    <>
      {mainSubMenu.nodes.map((menu, index) => (
        <Item
          uri={menu.uri}
          label={menu.label}
          target={menu.target}
          key={index}
        />
      ))}
    </>
  );
}

export default SubMenu;
