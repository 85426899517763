import React from "react";
import classnames from "classnames";
import * as style from "./style.module.css";
import { Icon } from '@rebrandly/styleguide'

function MenuList({ menus, closeMenu, useBtnLinkClass = false, isLogged = false }) {
  return (
    <nav className={style.nav_menu}>
      <ul className={style.nav_box}>
        {menus.map((item, index) => {
          return (
            <li key={index}>
              <a
                href={item.uri}
                target={item.target}
                className={classnames(style.nav_link, item.cssClasses, {
                  [style.btn_link]: useBtnLinkClass,
                  'btn_user_logged': isLogged
                })}
                onClick={closeMenu}
              >
                {item.cssClasses.includes('my_dashboard') && <Icon className='my_dashboard_icon' name="ic-avatar" size='xsmall' />}
                {item.label}
              </a>
            </li>
          )
        })}
      </ul>
    </nav>
  );
}

export default MenuList;
