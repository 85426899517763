import React from "react";
import classnames from "classnames";

import Link from "../../acf/link";

import Logo from "../../../images/svg/URL-Shortener.fileextension.svg";

import * as style from "./style.module.css";

function SmsHeader() {
  return (
    <>
      <Link className={style.logo_wrapp} to="/">
        <Logo />
        <span>| SMS links</span>
      </Link>
      <Link
        to="/book-a-demo"
        className={classnames("m-l-auto menu_button", style.mob_hide)}
      >
        Book a demo
      </Link>
    </>
  );
}

export default SmsHeader;
