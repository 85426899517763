import React, { PureComponent, Fragment } from 'react'
import { bool, string, node, func } from 'prop-types'
import get from 'lodash/get'
import URL from 'url-parse'
import { capitalize } from 'humanize-plus'

import { A, Card, CardBody, Text, Icon, SpotIllustration, Button } from '@rebrandly/styleguide'
import ContactUsDataForm from '../components/ContactUsDataForm'
import DefaultContent from '../components/DefaultContent'

import { submitContactForm } from '../utils/api'
import errorMap from '../utils/errorMap'
import { isOfficeHour } from '../utils/helpers'

import './ContactUs.css'

class DownloadGuide extends PureComponent {
  state = {
    success: false,
    isProcessing: false,
    formButtonClicked: null,
    requestType: 'email',
    errors: {},
    directUrl: false
  }

  handleSubmit = (data, captcha) => {
    this.setState({ isProcessing: true })

    const landingUrl = get(window, 'location.href')
    const navigationHistory = window.history
    let referral

    if (navigationHistory.length > 1) { // the user landed on this page from another Dashboard page
      referral = get(navigationHistory[navigationHistory.length - 2], 'pathname')
    } else {
      referral = get(window, 'rebrandly.routing.referer', 'Unknown')
    }

    let filteredData = {
      referral,
      requestType: this.state.requestType,
      landingUrl,
      requestInfo: this.state.formButtonClicked
    }

    Object.keys(data).forEach(key => {
      if (data[key]) {
        filteredData[key] = data[key]
      }
    })

    // @dev push event for GTag manager
    if (window && window.dataLayer) {
      let event = null
      switch (this.state.formButtonClicked) {
        case 'phone':
          event = 'ClickButtonMQLForm_BookACall'
          break
        case 'email':
          event = 'ClickButtonMQLForm_EmailUs'
          break
        default:
          break
      }
      event && window.dataLayer.push({ event })
    }

    return submitContactForm(this.props.env, filteredData, captcha)
        .then(() => {
          // if (this.state.formButtonClicked === 'calendar') {
          //   this.openCalendar()
          // } else {
          window.history.replaceState({}, '', '?ref=mql-success')
          this.props.onSuccess && this.props.onSuccess()
          // }

          this.setState({ isProcessing: false, success: true, requestType: null })
        })
        .catch(e => {
          let messages = {}
          const { source, errors, error } = e

          if (errors) {
            errors.forEach(e => {
              const msg = get(errorMap[source], [e.property, e.code])
              messages[e.property] = {theme: 'error', message: get(msg, 'message', 'Invalid format')}
            })
          } else if (error) {
            messages['captcha'] = {code: 'InvalidChallenge', message: capitalize(error)}
          }

          this.setState({ isProcessing: false, errors: messages })
        })
  }

  isWithContent = () => this.props.content

  render () {
    const {isChargeFree, onSuccess, isLogged, env, content, title, guideName, ...rest} = this.props

    return (
        <Fragment>
          {!this.state.requestType &&
              <div className='Container--narrow form'>
                <div className='flex center-xs'>
                  <SpotIllustration className='m-b-24' size='md' name='ill-spot-success'/>
                </div>
                <Text size='large' className='Text-SemiHero text-center m-b-12'>
                  {guideName}
                </Text>
                <div className='m-b-64 center-xs'>
                  <A href={this.props.downloadUrl} target='_blank'>
                    <Button
                        label='Download now'
                        size='lg'
                        theme='primary'
                        icon={{ name: 'ic-download' }}
                    />
                  </A>
                </div>
                <Text size='small' className='Text--SubDetail text-center m-b-12'>
                  Do you want to get in contact with our sales team?
                </Text>
                <ul className='MQL__cards'>
                  <div className='MQL__card flex-column'>
                    <A className='flex-column text-center' href={'https://rebrandly.sale/book-a-demo'} target='_blank'>
                      <Icon className='m-b-12 mql-icon' name='ic-calendar' size='medium' color='#2c96df' />
                      <Text size='small'>Schedule a meeting</Text>
                    </A>
                  </div>
                  {isOfficeHour() &&
                      <div className='MQL__card flex-column'>
                        <A className='flex-column text-center' onClick={() => this.setState({ requestType: 'phone' })}>
                          <Icon className='m-b-12 mql-icon' name='ic-phone-call' size='medium' color='#2c96df' />
                          <Text size='small'>Call us now</Text>
                        </A>
                      </div>
                  }
                </ul>
              </div>
          }
          {this.state.requestType  &&
              <ContactUsDataForm
                  env={env}
                  title={title || (this.isWithContent() ? 'Request a demo' : 'Our sales reps will get in touch!')}
                  errors={this.state.errors}
                  directUrl={this.state.directUrl}
                  phoneOnly={this.state.requestType === 'phone'}
                  submitButton={{
                    name: 'email',
                    size: 'lg',
                    theme: 'primary',
                    type: 'submit',
                    label: this.props.buttonLabel || (this.state.requestType === 'phone' ? 'Book a call' : 'Submit'),
                    onClick: () => this.setState({ formButtonClicked: this.state.requestType }),
                    isLoading: this.state.isProcessing && this.state.formButtonClicked === this.state.requestType,
                    block: true
                  }}
                  onSubmit={this.handleSubmit}
                  isLogged={isLogged}
                  onResetErrors={() => this.setState({ errors: null })}
                  content={this.isWithContent() && (content || <DefaultContent />)}
                  showDemoField
                  {...rest}
              />
          }
        </Fragment>
    )
  }
}

DownloadGuide.propTypes = {
  env: string,
  guideName: string,
  downloadUrl: string,
  isChargeFree: bool,
  buttonLabel: string,
  isLogged: bool,
  onSuccess: func,
  theme: string,
  title: string,
  content: node
}

DownloadGuide.defaultProps = {
  isChargeFree: true
}

export default DownloadGuide
