import React from 'react';
import Link from '../../acf/link';

import BlueLogo from '../../siteLogo/blueLogo';
import HubspotIcon from '../../../images/svg/hubspot-logo.svg'
import * as style from './style.module.css';

function HubspotHeader({ logoType = 'all' }) {
    
    return(
        <div className={style.logos_wrapp}>
            <div className={style.logo}>
                <BlueLogo />
            </div>
            {logoType === 'all' ? 
                <>
                    <div className={style.divider_vert_logos}></div>
                    <Link to='https://www.hubspot.com/' className={style.logo}>
                        <HubspotIcon/>
                    </Link>
                </>
            : null}
        </div>
    )
}

export default HubspotHeader;