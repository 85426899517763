import common from './common'
import development from './development'
import production from './production'
import merge from 'lodash/merge'

const defaultEnvironment = 'production'
const config = {
  development: development,
  production: production
}
export const env = process.env.APP_BUILD_ENV || defaultEnvironment

let finalConfig = { ...merge(common, config[env]), env }
export default finalConfig
