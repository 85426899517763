import React from "react";
import Link from "../acf/link";

function Item({ label, uri, target }) {
  return (
    <Link to={uri} target={target}>
      {label}
    </Link>
  );
}

export default Item;
