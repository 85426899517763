// extracted by mini-css-extract-plugin
export var btn_link = "navigation-module--btn_link--7aa70";
export var buttons_wrapp = "navigation-module--buttons_wrapp--839f1";
export var links_wrapp = "navigation-module--links_wrapp--eed44";
export var mega_menu = "navigation-module--mega_menu--da727";
export var mega_menu_0 = "navigation-module--mega_menu_0--5e0d6";
export var megamenu_content = "navigation-module--megamenu_content--f52e1";
export var megamenu_content_0 = "navigation-module--megamenu_content_0--3daf0";
export var nav_box = "navigation-module--nav_box--c4fa3";
export var nav_link = "navigation-module--nav_link--8deb4";
export var nav_menu = "navigation-module--nav_menu--49cab";
export var sub_menu_header = "navigation-module--sub_menu_header--34447";