import React from "react";
import {Link as GatsbyLink} from "gatsby";

function Link({to, children, ...props}) {
   
    try {
        const url = new URL(to);
        if (url.hostname === process.env.GATSBY_HOSTNAME) {
            return <GatsbyLink to={to} {...props}>{children}</GatsbyLink>
        }

        return <a href={to} {...props}>{children}</a>
    } catch (e) {
        return <GatsbyLink to={to} {...props}>{children}</GatsbyLink>
    }

}

export default Link;