import React from 'react'
import { Accordion, AccordionItem, Text, Icon } from '@rebrandly/styleguide'

import {Link} from 'gatsby'

import * as style from './style.module.css'

const leftColumn = [
  {
    to: '/branded-links',
    label: 'Links'
  },
  {
    to: '/qr-codes',
    label: 'QR Codes'
  },
  {
    to: '/rebrandly-ai',
    label: 'Rebrandly AI'
  },
  {
    to: '/api-solutions',
    label: 'API'
  },
  {
    to: '/analytics',
    label: 'Analytics'
  }
];

const rightColumn = [
  {
    to: '/agencies',
    label: 'Agencies',
    icon: 'ic-megaphone'
  },
  {
    to: '/developers',
    label: 'Developers',
    icon: 'ic-code'
  },
  {
    to: '/partners',
    label: 'Partners',
    icon: 'ic-hands'
  },
  {
    to: '/registrars',
    label: 'Domain Industry',
    icon: 'ic-domain-connect'
  }
]

const Products = ({ onClick }) => {
  const renderLeftColumn = () => {
    return (
      <ul>
        {leftColumn.map((item, index) => (
          <li key={item.label} className={style.menu_link_left}>
            <Link to={item.to} onClick={onClick}>
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    )
  }
  const renderRightColumn = () => {
    return (
      <ul>
        {rightColumn
          .map(item => (
            <li key={item.label}>
              <Link to={item.to} onClick={onClick}>
                <div className={style.menu_link_right}>
                  <div className='flex middle-xs'>
                    <div className={style.icon_wrapper}>
                      <Icon name={item.icon} color='var(--color-white)' />
                    </div>
                    <span className={style.menu_link_right_label}>{item.label}</span>
                  </div>
                </div>
              </Link>
            </li>
          ))
        }
      </ul>
    )
  }
  return (
    <>
      <div className='display-none display-md-flex'>
        <div className={style.products_menu_left}>
          <Text className='Text-Hero m-b-24'>Features</Text>
          {renderLeftColumn()}
        </div>

        <div className={style.products_menu_right}>
          <Text className='Text-Hero m-b-16 p-l-16'>Audiences</Text>
          <span className={style.background} />
          {renderRightColumn()}
        </div>
      </div>

      <div className='ResponsiveHeader display-md-none'>
        <Accordion>
          <AccordionItem title='Features'>
            <div className={style.products_menu_left}>
              {renderLeftColumn()}
            </div>
          </AccordionItem>
          <AccordionItem title='Audiences'>
            <div className={style.products_menu_left}>
              {renderRightColumn()}
            </div>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  )
}

export default Products
