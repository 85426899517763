import React, { Component } from "react";
import { func, bool, object } from "prop-types";
import { Modal, ModalContent, Loading } from "@rebrandly/styleguide";
import config from "../../config";
import ContactUs from "../contacts/containers/ContactUs";
import DownloadGuide from "../contacts/containers/DownloadGuide";
class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = { component: null };
  }

  componentDidMount() {
    this.setState({
      component: this.props.guideForm ? (
        <DownloadGuide env={config.env} {...this.props.formProps} />
      ) : (
        <ContactUs env={config.env} showHubspotForm {...this.props.formProps} />
      ),
    });
  }

  handleCloseModal = () => {
    this.props.onCloseContactModal();
  };

  renderContent() {
    if (this.props.page || this.props.showContactModal) {
      return this.state.component;
    }
    return null;
  }

  render() {
    if (this.props.page) {
      return (
        <div className="section ContactUs-website">
          {this.state.component ? (
            this.renderContent()
          ) : (
            <Loading
              size="lg"
              direction="vertical"
              label="Loading, please wait..."
            />
          )}
        </div>
      );
    }
    if (!this.props.showContactModal) return null;
    return (
      <Modal
        className="ContactUs-website"
        size="lg"
        isOpen
        showClose
        onClose={this.handleCloseModal}
      >
        <ModalContent>
          {this.state.component && this.renderContent()}
        </ModalContent>
      </Modal>
    );
  }
}

Contacts.propTypes = {
  onCloseContactModal: func,
  showContactModal: bool,
  formProps: object,
  page: bool,
  guideForm: bool,
};

export default Contacts;
