import React, {useState, useEffect} from 'react'
import classnames from 'classnames';
import PropTypes from 'prop-types';

import BlueLogo from '../../siteLogo/blueLogo';
import MobileModal from '../../modals/mobMenuModal';
import HomeMenu from '../../HeaderMenu/homeMenu';
import JobsMenu from '../../HeaderMenu/jobsMenu';
import WhiteLogo from '../../siteLogo/whiteLogo';

import MobIcon from '../../../images/svg/menu_icon.svg';

import * as style from './style.module.css';
import './style.css';

function DefaultHeader({ type }) {
  const [open, setOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(null);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    if (window.innerWidth > 1023) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openModal = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <>
      <div className={style.logo}>
        {type === 'default' ? <BlueLogo /> : <WhiteLogo />}
      </div>
      <button
        aria-label="open menu"
        className={classnames(
          style.mob_open_btn,
          [open && style.open],
          type === 'jobs' ? style.white_icon : null
        )}
        type="button"
        onClick={openModal}
      >
        <MobIcon />
      </button>
      <div className={classnames(style.m_hidden, style.header_menu)}>
        <HomeMenu closeMenu={() => setOpen(false)} />
      </div>

      {windowWidth <= 1023 && (
        <MobileModal isOpen={open} onClose={() => setOpen(false)}>
          <HomeMenu closeMenu={() => setOpen(false)} />
        </MobileModal>
      )}
    </>
  );
}

DefaultHeader.propTypes = {
  type: PropTypes.oneOf(['default', 'jobs']),
};

export default DefaultHeader;
