import { FREE_PLAN, LITE_PLAN, STARTER_PLAN, PREMIUM_PLAN, PRO_PLAN, ENTERPRISE_PLAN } from '../utils/pricing'

const conf = {
  plans: [
    {
      name: FREE_PLAN,
      id: '16f08fb9749d417eb142a326a2d0527c'
    },
    {
      name: LITE_PLAN,
      id: '0d63916f82a14c2f83cd4fb7a2f08d26'
    },
    {
      name: STARTER_PLAN,
      id: 'd6f4ec6a05464c7784d5ee5f4bb28b14'
    },
    {
      name: PRO_PLAN,
      id: 'e51137fe86de430690c34c2a9dce708f'
    },
    {
      name: PREMIUM_PLAN,
      id: 'cf982952ff094c998634a4ea56bb5998'
    },
    {
      name: ENTERPRISE_PLAN,
      id: 'enterprise_id'
    }
  ],
  api: {
    qrcodeGenerator: {
      baseUrl: 'https://test-qr.rebrandly.com/v1'
    }
  },
  smartLook: {
    projectKey: '8ace9f0171e14913e7250c18134e20a31c247a59'
  }
}
export default conf
