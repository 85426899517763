import React, {useEffect, useState} from 'react'
import {Icon, IconButton} from '@rebrandly/styleguide'

import * as style from './style.module.css'
import classNames from 'classnames'

const BioCampaignBanner = () => {
  const [open, setOpen] = useState(true)
  const [closing, setClosing] = useState(false)
  const [pathname, setPathname] = useState('')

  const onClose = () => {
    setClosing(true)
    setTimeout(() => setOpen(false), 250)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPathname(window?.location?.pathname || '')
    }
  }, [])

  if (!open || pathname?.includes('bio-tld')) return null
  return (
    <div className={classNames(style.bio_campaign, {[style.closing]: closing})}>
      <div className='container full-width'>
        <div className='flex-between middle-xs'>
          <div className='flex middle-xs' style={{ gap: '16px' }}>
            {/*<span className={style.icon}>
              <Icon color='#000' name='ic-domain' size='xsmall' />
            </span>*/}
            <p>
              🔥 Offer: Secure your digital identity - <a href='https://rbnd.bio/domain' className={style.anchor} onClick={onClose}>get a FREE .bio domain today!</a>
            </p>
          </div>

          <div>
            <IconButton
              theme='ghost'
              onClick={onClose}
              icon={{ name: 'ic-cross', size: 'sm', color: '#fff' }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BioCampaignBanner
