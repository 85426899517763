import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Link from "../acf/link";
import { Icon } from "@rebrandly/styleguide";

const TITLE = 'DOWNLOAD THE APP';

/**
 * FooterApps component that renders download links for mobile apps.
 *
 * This component uses a GraphQL query to fetch the links and images for the 
 * Google Play Store and Apple App Store from the WordPress backend.
 *
 * @returns {React.ReactElement} The rendered FooterApps component.
 */
function FooterApps() {
  const global_footer_apps = useStaticQuery(graphql`
    query globalFooterApps {
      wp {
        themeGeneralSettings {
          globalSettings {
            googlePlayLink
            googlePlayImage
            appStoreLink
            appStoreImage
          }
        }
      }
    }
  `);

  return (
    <div className="footer-apps">
      <p className="LinksFooter__Title footer-top-title">{TITLE}</p>
      <Link to={global_footer_apps?.wp?.themeGeneralSettings?.globalSettings?.appStoreLink}>
        <Icon
          name="ic-os-ios"
        />
      </Link>

      <Link to={global_footer_apps?.wp?.themeGeneralSettings?.globalSettings?.googlePlayLink}>
        <Icon
          name="ic-google-play"
        />
      </Link>
    </div>
  );
}

export default FooterApps;