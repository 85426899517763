import parse from "url-parse"
import { encode } from 'js-base64'
import config from "../config.js"

export const ALLOW_COOKIES = 'allow'

export const openContactFormRequestType = (type) => {
  const search = window.location.search ? window.location.search + `&requestType=${type}` : `?requestType=${type}`
  window.history.replaceState({}, '', search)
}

export const closeContactFormRequestType = (type) => {
  const regex = new RegExp(`[&,?]requestType=${type}`)

  let search = window.location.search.replace(regex, '')
  search = search.replace(/[&,?]ref=mql-success/, '')
  search = search || '/'

  window.history.pushState({}, '', search)
}

export const buildLoginUrl = (variant) => {
  const url = parse(config.dashboardBaseUrl)
  url.set('pathname', '/login')

  if (variant) {
    let buff = encode(JSON.stringify({
      planId: variant
    }), 'ascii')
    let opt = buff.toString('base64')
    url.set('query', { opt })
  }
  return url.href
}

/**
 * Splits an array into a specified number of chunks.
 *
 * This function divides the input array into `n` chunks. Each chunk is an array containing a portion
 * of the original array's elements. If the array cannot be evenly divided, the final chunk may be smaller
 * than the others.
 *
 * @param {Array} arr - The array to be split into chunks.
 * @param {number} n - The number of chunks to split the array into.
 * @returns {Array[]} An array containing the `n` chunks, each of which is an array of elements from the original array.
 */
export function chunkArray(arr, n) {
  const chunkLength = Math.max(arr.length / n, 1);
  let chunks = [];
  for (let i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arr.length) {
      chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
    }
  }
  return chunks;
}
