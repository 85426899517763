import React, {useContext} from 'react'
import { graphql, useStaticQuery} from "gatsby";
import MenuList from './menuList';
import * as style from './style.module.css';
import {AppContext} from '../layout/default'
import ButtonsMenu from './buttonsMenu'

function JobsMenu() {
    const { jobsMenu }  = useStaticQuery(graphql`
    query jobsMenu {
        jobsMenu: allWpMenuItem(filter: { locations: { eq: JOBS_HEADER_MENU } }) {
        nodes {
          uri
          label
          target
          cssClasses
        }
      }
    }
  `);
    const { user, cookieConsent } = useContext(AppContext)
    const showUserAsLogged = !!user.id && cookieConsent

    return (
      <div className='HeaderMenu'>
        <div className={style.links_wrapp}>
            <MenuList menus={jobsMenu.nodes}/>
        </div>
        <ButtonsMenu isLogged={showUserAsLogged} />
      </div>
    )
}

export default JobsMenu;
