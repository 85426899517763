import React from 'react';
import BlueLogo from '../../siteLogo/blueLogo';
import * as style from './style.module.css';

function SimpleHeader() {

  return(
    <div className={'flex middle-xs p-t-16 p-b-16'}>
      <div className={style.logo}>
        <BlueLogo />
      </div>
    </div>
  )
}

export default SimpleHeader;
