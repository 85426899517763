const Errors = {
  leadData: {
    companyEmail: {
      InvalidFormat: {
        message: "Must be a valid email",
        showNotification: false,
      },
    },
    website: {
      InvalidFormat: {
        message: "Must be a valid URL",
        showNotification: false,
      },
    },
    firstName: {
      InvalidMinLength: {
        message: "Value cannot be smaller than 2 characters",
        showNotification: false,
      },
    },
    lastName: {
      InvalidMinLength: {
        message: "Value cannot be smaller than 2 characters",
        showNotification: false,
      },
    },
    companyName: {
      InvalidMinLength: {
        message: "Value cannot be smaller than 2 characters",
        showNotification: false,
      },
    },
  },
};

export default Errors;
