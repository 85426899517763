export default {
  api: {
    middleware: {
      batchHandler: {
        baseUrl: 'https://test-middleware.rebrandly.com/v1'
      }
    },
    qrcodeGenerator: {
      baseUrl: 'https://test-qr.rebrandly.com/v1'
    },
    info: {
      baseUrl: 'https://www.test.rebrandly.com'
    }
  },
  freeTrialPlans: [{ value: 7, label: '7 days', id: 'd205d6ce775443a3ac92adf66fe4985e' }, { value: 14, label: '14 days', id: '87e9961026714fbdaf663ae0d7abda79' }]
}
