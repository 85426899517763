import React from "react";
import Item from "../footer/item";

/**
 * Links component that displays a list of menu items and an optional title.
 *
 * This component renders a list of links or buttons based on the provided menu items.
 * If the menu item label is "Contact Sales", it renders a button that triggers a modal.
 *
 * @param {Object} props - The props for the component.
 * @param {Array} props.menuList - The list of menu items to display.
 * @param {string} props.title - The title to display above the menu items.
 * @param {Function} props.toggleModal - The function to toggle the modal (optional).
 * @param {React.ReactNode} [props.children] - Optional children to render within the component.
 * @returns {React.ReactElement} The rendered Links component.
 */
function Links({ menuList = [], title, toggleModal, children }) {
  return (
    <>
      <div className="item footer-top-item">
        <p className="LinksFooter__Title footer-top-title">{title}</p>
        {menuList.map((menu, index) =>
          menu.label.toLowerCase() === "contact sales" ? (
            <button
              className="footer-contactSalesBtn"
              key={index}
              type="button"
              onClick={() => {
                toggleModal();
              }}
            >
              {menu.label}
            </button>
          ) : (
            <Item
              uri={menu.uri}
              label={menu.label}
              target={menu.target}
              key={index}
            />
          )
        )}
        {children ? children : null}
      </div>
    </>
  );
}

export default Links;
