import React, {Fragment} from 'react'
import {A, Text, Illustration, Icon} from '@rebrandly/styleguide'

import './DefaultContent.css'

const DefaultContent = () => {
  return (
      <div>
        <div className='m-b-36'>
          <Text size='large' className='Text-Title m-b-24'>
            Discover a better way to brand and manage your links
          </Text>
          <Text size='small' className='Text--SubDetail'>
            Get answers to your unique questions, and find out why Rebrandly is the right choice for your business.
          </Text>
        </div>

        <div className='m-b-36'>
          <Text size='medium' className='Text--SubDetail'>
            Get access to:
          </Text>
          <Text size='small' className='Text--SubDetail font-semibold m-t-12'>
            <ul>
              <li className='flex middle-xs m-b-12'><Illustration className='icon-wrapper m-r-12' name='ill-custom-solutions' size='tiny' color='var(--color-white)' />Custom solutions and pricing</li>
              <li className='flex middle-xs m-b-12'><Illustration className='icon-wrapper m-r-12' name='ill-advanced-analytics' size='tiny' color='var(--color-white)' />Advanced analytics and reporting</li>
              <li className='flex middle-xs m-b-12'><Illustration className='icon-wrapper m-r-12' name='ill-dedicated-support' size='tiny' color='var(--color-white)' />Dedicated support engineers</li>
              <li className='flex middle-xs m-b-12'><Illustration className='icon-wrapper m-r-12' name='ill-enterprise-team' size='tiny' color='var(--color-white)' />Enterprise team management features</li>
            </ul>
          </Text>
        </div>

        <div className='CallUsBox m-b-36'>
          <div className='Container--wide'>
            <Text size='large' className='Text--Detail'>
              Want to call us?
            </Text>
            <Text size='x-small' className='Text--SubDetail m-t-12'>
              Our sales team would love to hear from you!
            </Text>
            <div className='display-none display-md-flex middle-xs m-t-16'>
              <Icon className='m-r-8' name='ic-phone-call' size='xsmall' color='var(--color-grey-600)' />
              <Text className='Text-Hero' size='medium'>+1 (415) 702-2047</Text>
            </div>
            <A className='display-md-none' href='tel:+14157022047'>
              <div className='flex middle-xs'>
                <Icon className='m-r-8' name='ic-phone-call' size='xsmall' color='var(--color-blue-400)' />
                <Text size='medium'>+1 (415) 702-2047</Text>
              </div>
            </A>
          </div>
        </div>
      </div>
  )
}

export default DefaultContent
