import React from 'react';
import * as style from './style.module.css';
import { Sidebar } from '@rebrandly/styleguide'

function MobileModal({ children, isOpen, onClose }) {
    return (
      <Sidebar showClose onClose={onClose} isOpen={isOpen} position='right'>
        <div className={style.mobile_menu}>
          {children}
        </div>
      </Sidebar>
    )

}

export default MobileModal;
