import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import MenuList from "./menuList";

function ButtonsMenu(props) {
  const { buttonsMenu } = useStaticQuery(graphql`
    query buttonsMenu {
      buttonsMenu: allWpMenuItem(
        filter: { locations: { eq: BUTTONS_HEADER_MENU } }
        sort: { fields: order, order: ASC }
      ) {
        nodes {
          uri
          label
          target
          cssClasses
        }
      }
    }
  `);
  return <MenuList menus={buttonsMenu.nodes} useBtnLinkClass {...props} />;
}

export default ButtonsMenu;
