import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

function Image({ image, alt='Illustration', ...props }) {
  if (Object.prototype.toString.call(image) !== "[object Object]") {
    return null;
  }
  const icon = getImage(image?.localFile);
  return (
    <>
      {icon ? (
        <GatsbyImage image={icon} alt={image?.altText || alt} {...props} />
      ) : (
        <img {...props} src={image.sourceUrl} alt={image?.altText || alt}/>
      )}
    </>
  );
}

export default Image;
