import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import Image from "../acf/image";
import Link from "../acf/link";

/**
 * Social component that displays social media icons with links.
 *
 * This component fetches social media settings using a GraphQL query and renders
 * the corresponding icons as links. It supports two types: "main" and "full".
 * The "main" type displays a subset of social media links, while the "full" type
 * displays all available social media links.
 *
 * @param {Object} props - The props for the component.
 * @param {"main" | "full"} props.type - The type of social links to display.
 * @returns {React.ReactElement} The rendered Social component.
 */
function Social({ type }) {
  const global_socials = useStaticQuery(graphql`
    query globalSocialsSettings {
      wp {
        themeGeneralSettings {
          globalSettings {
            socials {
              url
              icon {
                id
                sourceUrl
              }
            }
            mainSocials {
              url
              icon {
                id
                sourceUrl
              }
            }
          }
        }
      }
    }
  `);

  let socialType = type === 'full';
  /* Split the Rebrandly icon from the socials */
  let socialItems = global_socials?.wp?.themeGeneralSettings?.globalSettings?.socials;
  const rebrandlyItem = socialItems[0];
  socialItems = socialItems.slice(1, socialItems.length);

  return (
    <div className="socials">
      <div className="wrap-social-rebrandly">
        <Link key={rebrandlyItem.icon.id} to={rebrandlyItem.url}>
          <Image image={rebrandlyItem.icon}></Image>
        </Link>
      </div>
      <div className="wrap-socials">
        {socialType &&
          <>
            {socialItems.map((item) => (
              <Link key={item.icon.id} to={item.url}>
                <Image image={item.icon}></Image>
              </Link>
            ))}
          </>
        }
        {global_socials?.wp?.themeGeneralSettings?.globalSettings?.mainSocials.map((item) => (
          <Link key={item.icon.id} to={item.url}>
            <Image image={item.icon}></Image>
          </Link>
        ))}
      </div>
    </div>
  )
}

Social.propTypes = {
  type: PropTypes.oneOf(["main", "full"]).isRequired,
};

export default Social;