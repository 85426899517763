import React, {useCallback, useEffect, useState} from 'react'
import Footer from '../footer'
import FooterLinks from '../footer-links'
import FooterTop from '../footer-top'
import Header from '../headers/header'

import Contacts from '../contacts/contacts'
import {ALLOW_COOKIES, closeContactFormRequestType, openContactFormRequestType} from '../../utils/helpers'
import config from '../../config'
import JSCookie from 'js-cookie'
import {v4 as uuidv4} from 'uuid'
import {useFlags, useLDClient} from 'gatsby-plugin-launchdarkly'
import {redirectToGoogleLogin} from '../../utils/signup-redirect'
import get from 'lodash/get'
import envConfig from '../../config/index'
import {FREE_PLAN} from '../../utils/pricing'
import GTagCookiesScript from './GTagCookiesScript'
import BioCampaignBanner from '../banners/BioCampaignBanner'

export const AppContext = React.createContext({user: {}})
const ContextProvider = AppContext.Provider


export const LDContext = React.createContext(null)
const injectZendesk = () => {
  var w = window
  var zE = w.zE
  w.zESettings = {
    webWidget: {
      chat: {
        suppress: false,
        notifications: {
          mobile: {
            disable: true
          }
        }
      }
    }
  }

  if (typeof zE === 'function') {
    zE('webWidget:on', 'chat:connected', function () {
    })
  } else if (config.zendesk) {
    var d = document

    function l() {
      var s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.id = 'ze-snippet'
      s.src = `https://static.zdassets.com/ekr/snippet.js?key=${config.zendesk.key}`
      var x = d.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    }

    l()
  }
}

const identifyLDUser = (client, country = '') => {
  let context = {
    id: JSCookie.get('accountId') || uuidv4(),
    country: country?.toLowerCase(),
    section: 'website',
    isLogged: !!JSCookie.get('accountId')
  }

  return client.identify({
    key: config.ld.sharedKey,
    name: 'anon',
    ...context,
    custom: context,
  })
}

function LayoutDefault({children, ...props}) {

  const [isLDInitialized, setIsLDInitialized] = useState(false)
  const [contextValue, setContextValue] = useState(() => {
    let userId
    let cookieConsent = JSCookie.get('cookieconsent_status') === ALLOW_COOKIES
    let userHash = JSCookie.get('userH')

    try {
      userId = JSON.parse(JSCookie.get('accountId'))
    } catch (e) {
      userId = JSCookie.get('accountId')
    }

    return {
      user: {id: userId, hash: userHash},
      cookieConsent
    }
  })
  const flags = useFlags()
  const ldClient = useLDClient()

  const [pagePath, setPagePath] = useState('')
  const [showContactModal, setShowContactModal] = useState(false)
  const wpPage = props?.data?.wpPage
  let currentPath = ''

  if (typeof window !== 'undefined') {
    currentPath = window.location.pathname
  }

  const toggleContactModal = () => {
    setShowContactModal(!showContactModal)
    if (!showContactModal) {
      openContactFormRequestType('email')
    } else {
      closeContactFormRequestType('email')
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPagePath(currentPath)
    }
  }, [currentPath])

  let showFooter = wpPage?.footerSettings?.footer !== 'none'

  if (['/newdomain', '/not-found', '/unauthorized'].includes(pagePath)) {
    showFooter = false
  }

  const showFooterLinks = showFooter && wpPage?.footerSettings?.menuListSettings !== 'Hide'

  /* =======================
   * HANDLERS
   * ======================= */
  const handleCookieConsent = useCallback((value) => {
    setContextValue((prevState) => ({...prevState, cookieConsent: value === ALLOW_COOKIES}))
  }, [])

  const handleGoogleInitialization = useCallback(() => {
    // console.log('Initialize GSI')
    window.google.accounts.id.initialize({
      client_id: config.google.client.id, // clientID from Google.
      ux_mode: 'popup',
      cancel_on_tap_outside: false,
      callback: ({credential}) => {
        redirectToGoogleLogin(credential, {plan: get(flags, 'plans.free.id', envConfig.plans.find((p) => p.name === FREE_PLAN).id)})
      }
    })

    window.google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed()) {
        // console.log(notification)
      }
    })
  }, [flags])

  /* =======================
   * EFFECTS
   * ======================= */
  useEffect(() => {
    if (ldClient) {
      fetch(`${envConfig.api.info.baseUrl}/info`)
        .then((response) => response.json())
        .then((body) => {
          return identifyLDUser(ldClient, body?.country)
        })
        .catch((e) => {
          console.log('Error while initializing LD: ', e)
          identifyLDUser(ldClient)
        })
        .finally(() => setIsLDInitialized(true))
    }
  }, [ldClient])

  useEffect(() => {
    // GOOGLE SIGN-IN
    // Since handleGoogleInitialization depends on flags, it is better to wait until LD is fully ready
    if (!contextValue.user.id && !!ldClient && isLDInitialized) {
      // console.log('adding google client script...')
      const script = document.createElement('script')
      script.src = config.google.client.scriptUri
      script.onload = handleGoogleInitialization
      script.async = true
      script.defer = true

      document.body.appendChild(script)
    }
  }, [contextValue.user.id, handleGoogleInitialization, ldClient, isLDInitialized])

  useEffect(() => {
    if (contextValue.cookieConsent) {
      injectZendesk()
    }
  }, [contextValue.cookieConsent, contextValue.user])


  const headerUrl = props?.data?.wpPage?.uri ?? pagePath;
  function removeTrailingSlash(str) {
    return str.replace(/\/+$/, '');
  }
  return (
    <LDContext.Provider value={isLDInitialized}>
      <ContextProvider value={contextValue}>
        <GTagCookiesScript onCookieConsentChange={handleCookieConsent}/>

        <BioCampaignBanner />
        <Header path={removeTrailingSlash(headerUrl)} />

        {children}

        {showFooter && (
          <Footer footerType={wpPage?.footerSettings?.footer} footerPadding={showFooterLinks}>
            {showFooterLinks && (
              <>
                <FooterTop toggleModal={toggleContactModal}/>
                <FooterLinks/>
              </>
            )}
            {showContactModal && (
              <Contacts
                formProps={{
                  withContent: false,
                  showSideContent: false,
                }}
                size="lg"
                showContactModal={showContactModal}
                onCloseContactModal={toggleContactModal}
              />
            )}
          </Footer>
        )}
      </ContextProvider>
    </LDContext.Provider>
  )
}

export default LayoutDefault
