import React from 'react';
import Link from '../acf/link';
import Logo from '../../images/svg/rebrandly-logo-white.svg'

function WhiteLogo() {
    return(
        <Link to="/" className="dark_logo_wrapp">
            <Logo/>
        </Link>
    )
}

export default WhiteLogo;