import React from 'react';

import Link from '../../acf/link';

import LinkGalleryLogo from '../../../images/svg/link-gallery-logo.svg';

import * as style from './style.module.css';


function LinkgalleryHeader() {
    return(
        <>
            <Link to='/' className={style.logo_wrapp}>
                <LinkGalleryLogo/>
            </Link>
        </>
    )
}

export default LinkgalleryHeader;