const config = env => {
  return env === 'development'
    ? {
      baseUrls: {
        api: 'https://api.test.rebrandly.com/v1',
        middleware: 'https://test-middleware.rebrandly.com/v1'
      },
      captcha: {
        contactUsKey: '6LcEi8MUAAAAAB2w0Oowjy_itIWjZhsxQUdQApEO'
      }
    }
    : {
      baseUrls: {
        api: 'https://api.rebrandly.com/v1',
        middleware: 'https://middleware.rebrandly.com/v1'
      },
      captcha: {
        contactUsKey: '6LcEi8MUAAAAAB2w0Oowjy_itIWjZhsxQUdQApEO'
      }
    }
}

export default config
