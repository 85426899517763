import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Image from "../acf/image";


function FooterLogo() {
    const logoFooter  = useStaticQuery(graphql`
    query globalLogoFooter {
      wp {
        themeGeneralSettings {
          globalSettings {
            footerLogo {
              sourceUrl
            }
          }
        }
      }
    }
  `);

    return(
        <div className="footer-logo">
            <Image image={logoFooter?.wp?.themeGeneralSettings?.globalSettings?.footerLogo} loading="lazy"></Image>
        </div>
    )
}

export default FooterLogo;