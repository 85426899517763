
const setUpHSFormsAndRevenueHero = (document) => {

  const loadHSForm = document.createElement("script");
  loadHSForm.src = "https://js.hsforms.net/forms/embed/v2.js";
  loadHSForm.onload = () => {
    const initHSForm = document.createElement("script");
    initHSForm.innerHTML = `hbspt.forms.create({region: "na1", portalId: "4412251", formId: "33bb6408-32cc-48aa-a701-adae3843a03d"});`;
    document.querySelector('#HubspotExternalContainer')?.appendChild(initHSForm);

    const loadRevenueHero = document.createElement("script");
    loadRevenueHero.src = "https://app.revenuehero.io/scheduler.min.js";
    loadRevenueHero.onload = function() {
      const HS_FORM_ID = 'hsForm_33bb6408-32cc-48aa-a701-adae3843a03d'
      const initRevenueHero = document.createElement("script");

      initRevenueHero.innerHTML = `
        window.hero = new RevenueHero({ routerId: '569' })
        hero.schedule('${HS_FORM_ID}')
      `
      let intrval = setInterval(() => {
        const iframe = document.querySelector('iframe.hs-form-iframe')
        const formFound = iframe?.contentWindow.document.querySelector(`#${HS_FORM_ID}`)
        if(!formFound) { return }
        
        document.body.appendChild(initRevenueHero);
        clearInterval(intrval);
        }, 500);
    
    }
    document.body.appendChild(loadRevenueHero);
  }

  document.body.appendChild(loadHSForm);
}

export default setUpHSFormsAndRevenueHero