import React, { PureComponent, Fragment } from 'react'
import { bool, string, node, func } from 'prop-types'
import get from 'lodash/get'
import URL from 'url-parse'
import { capitalize } from 'humanize-plus'

import { A, Card, CardBody, Text, Icon, SpotIllustration } from '@rebrandly/styleguide'
import ContactUsDataForm from '../components/ContactUsDataForm'
import DefaultContent from '../components/DefaultContent'

import { submitContactForm } from '../utils/api'
import errorMap from '../utils/errorMap'
import { isOfficeHour } from '../utils/helpers'

import './ContactUs.css'

class ContactUs extends PureComponent {
  state = {
    success: false,
    isProcessing: false,
    formButtonClicked: null,
    requestType: null,
    errors: {},
    directUrl: false
  }

  componentDidMount () {
    if ((window.location.search.indexOf('withContent') !== -1) || this.props.withContent) {
      return this.setState({ requestType: 'email', directUrl: true })
    }

    const queryType = get(new URL(window.location.href, true), 'query.requestType')
    const requestType = queryType || this.props.requestType

    if (requestType === 'email' || requestType === 'phone') {
      return this.setState({ requestType, directUrl: !!queryType })
    }
  }

  handleSubmit = (data, captcha) => {
    this.setState({ isProcessing: true })

    const landingUrl = get(window, 'location.href')
    const navigationHistory = window.history
    let referral

    if (navigationHistory.length > 1) { // the user landed on this page from another Dashboard page
      referral = get(navigationHistory[navigationHistory.length - 2], 'pathname')
    } else {
      referral = get(window, 'rebrandly.routing.referer', 'Unknown')
    }

    let filteredData = {
      referral,
      requestType: this.state.requestType,
      landingUrl,
      requestInfo: this.state.formButtonClicked
    }

    Object.keys(data).forEach(key => {
      if (data[key]) {
        filteredData[key] = data[key]
      }
    })

    // @dev push event for GTag manager
    if (window && window.dataLayer) {
      let event = null
      switch (this.state.formButtonClicked) {
        case 'phone':
          event = 'ClickButtonMQLForm_BookACall'
          break
        case 'email':
          event = 'ClickButtonMQLForm_EmailUs'
          break
        default:
          break
      }
      event && window.dataLayer.push({ event })
    }

    if(this.props.hideDirectQuestions) {
      filteredData.helpType = 'evaluate'
      filteredData.usage = 'other'
    }

    return submitContactForm(this.props.env, filteredData, captcha)
        .then(() => {
          // if (this.state.formButtonClicked === 'calendar') {
          //   this.openCalendar()
          // } else {
          window.history.replaceState({}, '', '?ref=mql-success')
          this.props.onSuccess && this.props.onSuccess()
          // }

          this.setState({ isProcessing: false, success: true, requestType: null })
        })
        .catch(e => {
          let messages = {}
          const { source, errors, error } = e

          if (errors) {
            errors.forEach(e => {
              const msg = get(errorMap[source], [e.property, e.code])
              messages[e.property] = {theme: 'error', message: get(msg, 'message', 'Invalid format')}
            })
          } else if (error) {
            messages['captcha'] = {code: 'InvalidChallenge', message: capitalize(error)}
          }

          this.setState({ isProcessing: false, errors: messages })
        })
  }

  isWithContent = () => this.props.withContent || (window.location.search.indexOf('withContent') !== -1 && !this.props.noContent)

  render () {
    const {isChargeFree, onSuccess, isLogged, env, content, title, ...rest} = this.props
    let handleOtherOptions
    if (!this.props.noSecondaryButton) {
      handleOtherOptions = () => this.setState({ formButtonClicked: this.state.requestType, requestType: null })
    }

    return (
        <Fragment>
          {!this.state.requestType &&
              <div className='Container--narrow form'>
                {this.state.success &&
                    <div className='flex center-xs'>
                      <SpotIllustration className='m-b-24' size='md' name='ill-spot-success'/>
                    </div>
                }
                <Text size='large' className='Text-SemiHero text-center m-b-12'>
                  {this.state.success
                      ? 'Request received'
                      : 'How would you like to connect with Rebrandly?'
                  }
                </Text>
                {this.state.success &&
                    <Text size='small' className='Text--SubDetail text-center m-b-48'>
                      Our team will be in touch as soon as possible.<br />If you want to accelerate the process, please feel free to:
                    </Text>
                }
                <ul className='MQL__cards m-t-36'>
                  <div className='MQL__card flex-column'>
                    <A className='flex-column text-center' href={'https://rebrandly.sale/book-a-demo'} target='_blank'>
                      <Icon className='m-b-12 mql-icon' name='ic-calendar' size='medium' color='#2c96df' />
                      <Text size='small'>Schedule a meeting</Text>
                    </A>
                  </div>
                  {!this.state.success &&
                      <div className='MQL__card flex-column'>
                        <A className='flex-column text-center' onClick={() => this.setState({ requestType: 'email' })}>
                          <Icon className='m-b-12 mql-icon' name='ic-request' size='medium' color='#2c96df' />
                          <Text size='small'>Submit a request</Text>
                        </A>
                      </div>
                  }
                  {isOfficeHour() &&
                      <div className='MQL__card flex-column'>
                        <A className='flex-column text-center' onClick={() => this.setState({ requestType: 'phone' })}>
                          <Icon className='m-b-12 mql-icon' name='ic-phone-call' size='medium' color='#2c96df' />
                          <Text size='small'>Call us now</Text>
                        </A>
                      </div>
                  }
                </ul>
              </div>
          }
          {this.state.requestType  &&
              <ContactUsDataForm
                  env={env}
                  title={title || (this.isWithContent() ? 'Any questions?' : 'Our sales reps will get in touch!')}
                  errors={this.state.errors}
                  directUrl={this.state.directUrl}
                  phoneOnly={this.state.requestType === 'phone'}
                  submitButton={{
                    name: 'email',
                    size: 'lg',
                    theme: 'primary',
                    type: 'submit',
                    label: this.props.buttonLabel || (this.state.requestType === 'phone' ? 'Book a call' : 'Submit'),
                    onClick: () => this.setState({ formButtonClicked: this.state.requestType }),
                    isLoading: this.state.isProcessing && this.state.formButtonClicked === this.state.requestType,
                    block: true
                  }}
                  onOtherOptions={handleOtherOptions}
                  onSubmit={this.handleSubmit}
                  isLogged={isLogged}
                  onResetErrors={() => this.setState({ errors: null })}
                  content={this.isWithContent() && (content || <DefaultContent />)}
                  {...rest}
              />
          }
        </Fragment>
    )
  }
}

ContactUs.propTypes = {
  env: string,
  isChargeFree: bool,
  withContent: bool,
  requestType: string,
  buttonLabel: string,
  hideDirectQuestions: bool,
  isLogged: bool,
  noContent: bool,
  noSecondaryButton: bool,
  onSuccess: func,
  theme: string,
  title: string,
  content: node
}

ContactUs.defaultProps = {
  isChargeFree: true
}

export default ContactUs
