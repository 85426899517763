import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import DefaultHeader from './defaultHeader';
import DarkHeader from './darkHeader';
import SmsHeader from './smsHeader';
import LinkgalleryHeader from './linkgalleryHeader';

import * as style from './style.module.css';
import HubspotHeader from './hubspotHeader';
import SimpleHeader from "./simpleHeader/simpleHeader";

function Header({path}) {

  let headerComponent;
  let headerType;

  switch (path) {
    case '/':
      headerComponent = <DefaultHeader type='default'/>;
      headerType = 'default';
      break;
    case '/enterprise':
      headerComponent = <DarkHeader/>;
      headerType = 'dark'
      break;
    case '/gatsbypreview/enterprise':
      headerComponent = <DarkHeader/>;
      headerType = 'dark'
      break;
    case '/jobs' :
      headerComponent = <DefaultHeader type='jobs'/>;
      headerType = 'jobs'
      break;
    case '/sms' :
      headerComponent = <SmsHeader/>;
      headerType = 'sms'
      break;
    case '/link-management' :
      headerComponent = null;
      headerType = ''
      break;
    case '/link-gallery' :
      headerComponent = <LinkgalleryHeader/>;
      headerType = 'link_gallery'
      break;
    case '/unauthorized' :
      headerComponent = null;
      headerType = 'unauthorized'
      break;
    case '/how-to-build-a-brand' :
      headerComponent = <HubspotHeader/>
      headerType = 'build_brand'
      break;
    case '/social-media-checklist' :
      headerComponent = <HubspotHeader/>
      headerType = 'build_brand'
      break;
    case '/linkmanagement-form' :
      headerComponent = <HubspotHeader logoType='main'/>
      headerType = 'build_brand'
      break;
    case '/not-found':
      headerComponent = <SimpleHeader/>;
      headerType = 'default';
      break;
    default:
      headerComponent = <DefaultHeader type='default'/>;
      headerType = 'default';
      break;
  }
  return (
    <>
      {headerComponent &&
        <header className={classnames(style.header, style[`header_${headerType}`], [`header_${headerType}`])}>
          <div className={classnames('container', style.header_container)}>
            {headerComponent}
          </div>
        </header>
      }
    </>
  );
}

Header.propTypes = {
  path: PropTypes.string.isRequired,
};
export default Header;
