export default {
  api: {
    middleware: {
      batchHandler: {
        baseUrl: 'https://middleware.rebrandly.com/v1'
      }
    },
    qrcodeGenerator: {
      baseUrl: 'https://qr.rebrandly.com/v1'
    },
    info: {
      baseUrl: 'https://rebrandly.com'
    }
  },
  freeTrialPlans: [{ value: 7, label: '7 days', id: '2e275ea639294acba1397d9f4a24f56d' }, { value: 14, label: '14 days', id: '4bcf9d2750f34b8a89135fd6cf20858d' }]
}
