import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Links from "./links";
import Social from "../socials";
import FooterApps from "../footer-apps";
import "./style.css";

const CONSTANTS = {
  COMPANY: 'Company',
  PRODUCTS: 'Products',
  RESOURCES: 'Resources',
  LEGAL: 'Legal',
  SUPPORT: 'Support',
  CONTACT_US: 'Contact Us',
  FOLLOW_US: 'Follow Us',
  REPORT_ABUSE: 'Report abuse'
}

/**
 * FooterTop component that displays various sections of footer menus and other components.
 *
 * This component fetches multiple footer menus using a GraphQL query and displays
 * them in a responsive layout, with different structures for different screen sizes.
 *
 * @param {Object} props - The props for the component.
 * @param {Function} props.toggleModal - The function to toggle the modal.
 * @returns {React.ReactElement} The rendered FooterTop component.
 */
function FooterTop({ toggleModal }) {
  const {
    footerCompany,
    footerProducts,
    footerResources,
    footerContactUs,
    footerLegal,
    footerSupport
  } = useStaticQuery(graphql`
    query FooterMenuTop {
      footerCompany: allWpMenuItem(
        filter: { locations: { eq: FOOTER_COMPANY } }
      ) {
        nodes {
          uri
          label
          target
        }
      }
      footerProducts: allWpMenuItem(
        filter: { locations: { eq: FOOTER_PRODUCTS } }
        sort: { fields: order, order: ASC }
      ) {
        nodes {
          uri
          label
          target
        }
      }
      footerResources: allWpMenuItem(
        filter: { locations: { eq: FOOTER_RESOURCES } }
      ) {
        nodes {
          uri
          label
          target
        }
      }
      footerContactUs: allWpMenuItem(
        filter: { locations: { eq: FOOTER_CONTACT_US } }
      ) {
        nodes {
          uri
          label
          target
        }
      }
      footerLegal: allWpMenuItem(
        filter: { locations: { eq: FOOTER_LEGAL } }
      ) {
        nodes {
          uri
          label
          target
        }
      }
      footerSupport: allWpMenuItem(
        filter: { locations: { eq: FOOTER_SUPPORT } }
      ) {
        nodes {
          uri
          label
          target
        }
      }
    }
  `);

  return (
    <div className="footer-top">

      {/* Large Layout */}
      <div className="container footer-top-flex-box display-lg">
        <Links title={CONSTANTS.COMPANY} menuList={footerCompany.nodes} />
        <Links title={CONSTANTS.PRODUCTS} menuList={footerProducts.nodes} />
        <Links title={CONSTANTS.RESOURCES} menuList={footerResources.nodes} />
        <Links title={CONSTANTS.LEGAL} menuList={footerLegal.nodes}>
          <div className="m-t-24">
            <Links title={CONSTANTS.SUPPORT} menuList={footerSupport.nodes} />
          </div>
        </Links>
        <Links
          title={CONSTANTS.CONTACT_US}
          menuList={footerContactUs.nodes}
          toggleModal={toggleModal}
        >
          <ReportAbuseLink />
        </Links>
        <Links
          title={CONSTANTS.FOLLOW_US}
          toggleModal={toggleModal}
        >
          <Social type="full" />
          <FooterApps />
        </Links>
      </div>

      {/* Tablet Layout */}
      <div className="container footer-top-flex-box display-md">
        <Links title={CONSTANTS.COMPANY} menuList={footerCompany.nodes}>
          <div className="m-t-24">
            <Links title={CONSTANTS.LEGAL} menuList={footerLegal.nodes}>
              <div className="m-t-24">
                <Links title={CONSTANTS.SUPPORT} menuList={footerSupport.nodes} />
              </div>
            </Links>
          </div>
        </Links>

        <Links title={CONSTANTS.PRODUCTS} menuList={footerProducts.nodes}>
          <div className="m-t-24">
            <Links
              title={CONSTANTS.CONTACT_US}
              menuList={footerContactUs.nodes}
              toggleModal={toggleModal}
            >
              <ReportAbuseLink />
            </Links>
          </div>
        </Links>


        <Links title={CONSTANTS.RESOURCES} menuList={footerResources.nodes}>
          <div className="m-t-24">
            <Links
              title={CONSTANTS.FOLLOW_US}
              toggleModal={toggleModal}
            >
              <Social type="full" />
              <FooterApps />
            </Links>
          </div>
        </Links>

      </div>

      {/* Phone Layout */}
      <div className="container footer-top-flex-box display-sm">
        <Links title={CONSTANTS.COMPANY} menuList={footerCompany.nodes}>
          <div className="m-t-24">
            <Links title={CONSTANTS.RESOURCES} menuList={footerResources.nodes} />
          </div>
          <div className="m-t-24">
            <Links title={CONSTANTS.LEGAL} menuList={footerLegal.nodes}>
              <div className="m-t-24">
                <Links title={CONSTANTS.SUPPORT} menuList={footerSupport.nodes} />
              </div>
            </Links>
          </div>
        </Links>

        <Links title={CONSTANTS.PRODUCTS} menuList={footerProducts.nodes}>
          <div className="m-t-24">
            <Links
              title={CONSTANTS.CONTACT_US}
              menuList={footerContactUs.nodes}
              toggleModal={toggleModal}
            >
              <ReportAbuseLink />
            </Links>
          </div>
          <div className="m-t-24">
            <Links
              title={CONSTANTS.FOLLOW_US}
              toggleModal={toggleModal}
            >
              <Social type="full" />
              <FooterApps />
            </Links>
          </div>
        </Links>
      </div>
    </div>
  );
}

export default FooterTop;

const ReportAbuseLink = () => {
  return <a
    href="mailto:support@rebrandly.com?subject=Abuse"
    rel="noopener noreferrer"
  >
    {CONSTANTS.REPORT_ABUSE}
  </a>
}
